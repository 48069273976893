import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';

const AddCardGroupModal = ({ isOpen, onClose, onSave }) => {
const { currentUser } = useAuth();
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl= process.env.REACT_APP_API_URL;

  const policies = useSelector(state => state.policies.activePolicies);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const payload = {
          userId: currentUser.userId,
          groupName: groupName,
          description: description,
          policyNumber: policyNumber,
          transactionType: 'ADD'
      };

      const response = await fetch(`${apiUrl}/dataServer`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              action: 'addOrEditCardGroup',
              payload: payload
          })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      let result;
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
        result = await response.json(); // Process it as JSON
      } else {
        result = await response.text(); // Process it as text
      }   
      console.log('Result: ', result);  
      onSave(result); 
      onClose();
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to submit card group:', error);      
      setIsLoading(false);
      onSave(error);
    }
  };

  if (!isOpen) return null;

  return (
    <>
    {isLoading &&
    <Spinner/>
    }
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-10">
      <div className="bg-white p-4 rounded-lg shadow-xl max-w-lg w-full">
        <h2 className="text-lg font-bold mb-4">Add New Account</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="groupName" className="block font-medium text-gray-700">Account Name</label>
            <input
              type="text"
              id="groupName"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="description" className="block font-medium text-gray-700">Description</label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="policyNumber" className="block font-medium text-gray-700">Policy Number</label>
            <select
  id="policyNumber"
  value={policyNumber}
  onChange={(e) => setPolicyNumber(e.target.value)}
  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
  required
>
  <option value="">Select A Policy</option> 
  {policies.map(policy => (
    <option key={policy.PolicyNumber} value={policy.PolicyNumber}>
      {policy.PolicyNumber} - {policy.Description}
    </option>
  ))}
</select>
          </div>
          <div className="flex justify-end space-x-4">
            <button type="button" onClick={onClose} className="py-2 px-4 border border-gray-300 rounded-md shadow-sm">Cancel</button>
            <button type="submit" className="py-2 px-4 themed-button text-white rounded-md shadow-sm">Save</button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default AddCardGroupModal;
