const defaultTheme = {
  primary: '#F16528',
  secondary: '#055fe7',
  primaryText: '#000000', // default primary text color
  secondaryText: '#ffffff', // default secondary text color
  BtnTxtPrimaryColor: '#ffffff',
  BtnTxtHoverColor: '#ffffff',
  menuText: '#000',
  tenantId: 'Go-Transport-LLC-o5bl2',
  logo: '/logos/fuellink-temp.png',
  favicon: '/logos/fuellinkfav.ico',
  pageTitle: 'FuelLink - Fuel Solutions',
  markerIcon: '',
  loginPrimaryBG: '#F16528',
  loginBtnPrimary: '#055fe7',
  loginBtnHover: '#033dab',
  loginImage: '/logos/fuellink-temp.png',
  logoSize: 'medium',
  onBehalfOf: '',
};
const themes = {
  'gofuel.fuellink.net': { //Go Fuel white label
    primary: '#74cc10',
    secondary: '#000000',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/Go Fuel Network logo.png',
    favicon: '/logos/gofuelfav.ico',
    pageTitle: 'Go Fuel - Fuel Solutions',
    markerIcon: '',
    loginPrimaryBG: '#74cc10',
    loginBtnPrimary: '#74cc10',
    loginBtnHover: '#000000',
    loginImage: '/logos/Go Fuel Network logo.png',
    logoSize: 'medium',
    onBehalfOf: '',
  },
  'fbt.fuellink.net': { //FBT
    primary: '#000000',
    secondary: '#f16528',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/FBT_Black_20.png',
    favicon: '/logos/favicon_fbt.ico',
    pageTitle: 'FBT Fuel',
    markerIcon: '',
    loginPrimaryBG: '#000000',
    loginBtnPrimary: '#000000',
    loginBtnHover: '#f28352',
    loginImage: '/logos/FBT_Black_20.png',
    logoSize: 'medium',
    onBehalfOf: '',
  },
  // 'gofuel.fuellink.net': { //Go Fuel non-white-label
  //   primary: '#F16528',
  //   secondary: '#055fe7',
  //   primaryText: '#000000',
  //   secondaryText: '#ffffff',
  //   BtnTxtPrimaryColor: '#ffffff',
  //   BtnTxtHoverColor: '#ffffff',
  //   menuText: '#000',
  //   tenantId: 'Go-Transport-LLC-o5bl2',
  //   logo: '/logos/fuellink-temp.png',
  //   favicon: '/logos/fuellinkfav.ico',
  //   pageTitle: 'FuelLink - Fuel Solutions',
  //   markerIcon: '',
  //   loginPrimaryBG: '#F16528',
  //   loginBtnPrimary: '#055fe7',
  //   loginBtnHover: '#033dab',
  //   loginImage: '/logos/fuellink-temp.png',
  //   logoSize: 'medium',
  //   onBehalfOf: 'Go Fuel',
  // },
  'asdasdasda': { //eCapital whitelabel
    primary: '#5e707e',
    secondary: '#f7901e',
    primaryText: '#ffffff',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/ecapital_logo.png',
    favicon: '/logos/ecapital_favicon.webp',
    pageTitle: 'eCapital Fuel',
    markerIcon: '',
    loginPrimaryBG: '#5e707e',
    loginBtnPrimary: '#5e707e',
    loginBtnHover: '#f7901e',
    loginImage: '/logos/ecapital_logo.png',
    logoSize: 'medium',
    onBehalfOf: '',
  },
  'ecapital.fuellink.net': { //ecapital non-white-label
    primary: '#F16528',
    secondary: '#055fe7',
    primaryText: '#000000',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/fuellink-temp.png',
    favicon: '/logos/fuellinkfav.ico',
    pageTitle: 'FuelLink - Fuel Solutions',
    markerIcon: '',
    loginPrimaryBG: '#F16528',
    loginBtnPrimary: '#055fe7',
    loginBtnHover: '#033dab',
    loginImage: '/logos/fuellink-temp.png',
    logoSize: 'medium',
    onBehalfOf: 'eCapital',
  },
  'intellitrux.fuellink.net': { //Intellitrux Fuel
    primary: '#005DA9',
    secondary: '#F16528',
    primaryText: '#ffffff',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/intellitrux_fuel.png',
    favicon: '/logos/intellitrux_favicon.ico',
    pageTitle: 'INTELLITRUX™ Fuel',
    markerIcon: '',
    loginPrimaryBG: '#005DA9',
    loginBtnPrimary: '#005DA9',
    loginBtnHover: '#F16528',
    loginImage: '/logos/intellitrux_fuel.png',
    logoSize: 'medium',
    onBehalfOf: '',
  },
  'integrity.fuellink.net': { //Integrity Factoring
    primary: '#1f365c',
    secondary: '#f7901e',
    primaryText: '#ffffff',
    secondaryText: '#000000',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#000000',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/integrity_factoring_1.png',
    favicon: '/logos/integrity_fav.png',
    pageTitle: 'Integrity Factoring Fuel',
    markerIcon: '',
    loginPrimaryBG: '#1f365c',
    loginBtnPrimary: '#1f365c',
    loginBtnHover: '#f7901e',
    loginImage: '/logos/integrity_login_banner.png',
    logoSize: 'medium',
    onBehalfOf: '',
  },
  'aqha.fuellink.net': { //AQHA
    primary: '#6284a9',
    secondary: '#cd001a',
    primaryText: '#ffffff',
    secondaryText: '#000000',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/aqha_logo_3.png',
    favicon: '/logos/aqha_favicon.ico',
    pageTitle: 'AQHA Fuel - AQHA',
    markerIcon: '/logos/aqha_marker_2.png',
    loginPrimaryBG: '#c1a481',
    loginBtnPrimary: '#ec1e27',
    loginBtnHover: '#b50015',
    loginImage: '/logos/aqha_login_banner.png',
    logoSize: 'large',
    onBehalfOf: '',
  },
  'ocalhost': { //Pelican Fueling
    primary: '#002f67',
    secondary: '#60a4d4',
    primaryText: '#ffffff',
    secondaryText: '#000000',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/logo-pelican.webp',
    favicon: 'https://pelicanfueling.com/wp-content/uploads/2022/03/favicon-pelican.png',
    pageTitle: 'Pelican Fueling',
    loginPrimaryBG: '#002f67',
    loginBtnPrimary: '#002f67',
    loginBtnHover: '#60a4d4',
    loginImage: '/logos/logo-pelican.webp',
    logoSize: 'medium',
    onBehalfOf: '',
  },
  'ocalhost': { //bg
    primary: '#192f5c',
    secondary: '#0334ad',
    primaryText: '#ffffff',
    secondaryText: '#ffffff',
    BtnTxtPrimaryColor: '#ffffff',
    BtnTxtHoverColor: '#ffffff',
    menuText: '#000',
    tenantId: 'Go-Transport-LLC-o5bl2',
    logo: '/logos/bg_logo.png',
    favicon: '/logos/bg_favicon.ico',
    pageTitle: 'Pelican Fueling',
    loginPrimaryBG: '#192f5c',
    loginBtnPrimary: '#192f5c',
    loginBtnHover: '#60a4d4',
    loginImage: '/logos/bg_logo.png',
    logoSize: 'medium',
    onBehalfOf: '',
  },
  //   // 'localhost': { //Baker Garrington      
//   //   primary: '#192f5c',
//   //   secondary: '#0334ad',
//   //   primaryText: '#fff',
//   //   secondaryText: '#ffffff',
//   //   menuText:'#000',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/bg_logo.png',
//   //   favicon: '/logos/bg_favicon.ico',
//   //   pageTitle: 'BG - Fuel Solutions'
//   // },
};

export const getThemeForDomain = (domain) => {
  return themes[domain] || defaultTheme;
};

// const defaultTheme = {
//   primary: '#F16528',
//   secondary: '#055fe7',
//   primaryText: '#000000', // default primary text color
//   secondaryText: '#ffffff', // default secondary text color
//   BtnTxtPrimaryColor: '#ffffff',
//   BtnTxtHoverColor: '#ffffff',
//   menuText: '#000',
//   tenantId: 'Go-Transport-LLC-o5bl2',
//   logo: '/logos/fuellink-temp.png',
//   favicon: '/logos/fuellinkfav.ico',
//   pageTitle: 'FuelLink - Fuel Solutions',
//   markerIcon: ''
// };
// const themes = {
//   // 'localhost': {
//   'gofuel.fuellink.net': { //Go Fuel
//     primary: '#74cc10',
//     secondary: '#000000',
//     primaryText: '#000000',
//     secondaryText: '#ffffff',
//     BtnTxtPrimaryColor: '#ffffff',
//     BtnTxtHoverColor: '#ffffff',
//     menuText: '#000',
//     tenantId: 'Go-Transport-LLC-o5bl2',
//     logo: '/logos/Go Fuel Network logo.png',
//     favicon: '/logos/gofuelfav.ico',
//     pageTitle: 'Go Fuel - Fuel Solutions',
//     markerIcon: ''
//   },
//   'ecapital.fuellink.net': { //eCapital
//     primary: '#5e707e',
//     secondary: '#f7901e',
//     primaryText: '#ffffff',
//     secondaryText: '#ffffff',
//     BtnTxtPrimaryColor: '#ffffff',
//     BtnTxtHoverColor: '#ffffff',
//     menuText: '#000',
//     tenantId: 'Go-Transport-LLC-o5bl2',
//     logo: '/logos/ecapital_logo.png',
//     favicon: '/logos/ecapital_favicon.webp',
//     pageTitle: 'eCapital Fuel',
//     markerIcon: ''
//   },
//   'intellitrux.fuellink.net': { //Intellitrux Fuel
//     primary: '#005DA9',
//     secondary: '#F16528',
//     primaryText: '#ffffff',
//     secondaryText: '#ffffff',
//     BtnTxtPrimaryColor: '#ffffff',
//     BtnTxtHoverColor: '#ffffff',
//     menuText: '#000',
//     tenantId: 'Go-Transport-LLC-o5bl2',
//     logo: '/logos/intellitrux_fuel.png',
//     favicon: '/logos/intellitrux_favicon.ico',
//     pageTitle: 'INTELLITRUX™ Fuel',
//     markerIcon: ''
//   },
//   'ocalhost': { //Integrity Factoring
//     primary: '#1f365c',
//     secondary: '#f7901e',
//     primaryText: '#ffffff',
//     secondaryText: '#000000',
//     BtnTxtPrimaryColor: '#ffffff',
//     BtnTxtHoverColor: '#000000',
//     menuText: '#000',
//     tenantId: 'Go-Transport-LLC-o5bl2',
//     logo: '/logos/integrity_factoring.png',
//     favicon: '/logos/integrity_fav.png',
//     pageTitle: 'Integrity Factoring Fuel',
//     markerIcon: ''
//   },
//   // 'ocalhost': {//AQHA #1
//   //   primary: '#c1a481',
//   //   secondary: '#cd001a',
//   //   primaryText: '#ffffff',
//   //   secondaryText: '#000000',
//   //   BtnTxtPrimaryColor: '#000000',
//   //   BtnTxtHoverColor: '#ffffff',
//   //   menuText: '#000',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/AQHA_LOGO_BLACK.png',
//   //   favicon: '/logos/fuellinkfav.ico',
//   //   pageTitle: 'AQHA FUEL' 
//   // },
//   'localhost': {//AQHA #2
//     primary: '#6284a9',
//     secondary: '#cd001a',
//     primaryText: '#ffffff',
//     secondaryText: '#000000',
//     BtnTxtPrimaryColor: '#ffffff',
//     BtnTxtHoverColor: '#ffffff',
//     menuText: '#000',
//     tenantId: 'Go-Transport-LLC-o5bl2',
//     logo: '/logos/aqha_logo_3.png',
//     favicon: '/logos/aqha_favicon.ico',
//     pageTitle: 'AQHA Fuel - AQHA',
//     markerIcon: '/logos/aqha_marker_2.png'
//   },
//   // 'ocalhost': { //FilAMar      
//   //   primary: '#414141',
//   //   secondary: '#FFC844',
//   //   primaryText: '#fff',
//   //   secondaryText: '#000',
//   //   BtnTxtPrimaryColor: '#ffffff',
//   //   BtnTxtHoverColor: '#000',
//   //   menuText: '#000',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/filalogo.png',
//   //   favicon: '/logos/filamar_favicon.ico',
//   //   pageTitle: 'FilaMar Fuel'
//   // },
//   // 'ocalhost': { //Thunder Funding      
//   //   primary: '#d67d07',
//   //   secondary: '#FFC844',
//   //   primaryText: '#fff',
//   //   secondaryText: '#000',
//   //   BtnTxtPrimaryColor: '#ffffff',
//   //   BtnTxtHoverColor: '#000',
//   //   menuText: '#000',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/Thunder_funding_logo.png',
//   //   favicon: '/logos/fuellinkfav.ico',
//   //   pageTitle: 'Thunder Funding Fuel'
//   // },
//   // 'ocalhost': { //Driver Advantage      
//   //   primary: '#bc3039',
//   //   secondary: '#0C2340',
//   //   primaryText: '#fff',
//   //   secondaryText: '#000',
//   //   BtnTxtPrimaryColor: '#ffffff',
//   //   BtnTxtHoverColor: '#fff',
//   //   menuText: '#000',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/driver_advantage_fuel_logo.png',
//   //   favicon: '/logos/driver_advantage_favicon.ico',
//   //   pageTitle: 'Driver Advantage Fuel'
//   // },
//   // 'ocalhost': { //Porter Funding      
//   //   primary: '#067cbc',
//   //   secondary: '#14e2ee',
//   //   primaryText: '#fff',
//   //   secondaryText: '#000',
//   //   BtnTxtPrimaryColor: '#ffffff',
//   //   BtnTxtHoverColor: '#000000',
//   //   menuText: '#000',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/Porter_funding.png',
//   //   favicon: '/logos/fuellinkfav.ico',
//   //   pageTitle: 'Porter Funding Fuel'
//   // },
//   // 'ocalhost': { //sample      
//   //   primary: '#288c62',
//   //   secondary: '#114a32',
//   //   primaryText: '#fff',
//   //   secondaryText: '#000',
//   //   BtnTxtPrimaryColor: '#ffffff',
//   //   BtnTxtHoverColor: '#fff',
//   //   menuText: '#000',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/outgo_logo.png',
//   //   favicon: '/logos/fuellinkfav.ico',
//   //   pageTitle: 'Outgo Fuel'
//   // },
//   // 'ocalhost': { //Emilys company      
//   //   primary: '#fc03ca',
//   //   secondary: '#f2ba35',
//   //   primaryText: '#000',
//   //   secondaryText: '#ffffff',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/emilys_logo.jpg',
//   // },
//   // 'ocalhost': { //Madisons company      
//   //   primary: '#00f2ff',
//   //   secondary: '#f2ba35',
//   //   primaryText: '#000',
//   //   secondaryText: '#ffffff',
//   //   tenantId: 'Go-Transport-LLC-o5bl2',
//   //   logo: '/logos/madision_logo.png',
//   // },
//   // 'customerB.com': {
//   //   primary: '#38b2ac',
//   //   secondary: '#f56565',
//   //   primaryText: '#f56565',
//   //   secondaryText: '#38b2ac',
//   //   tenantId: 'tenantIdForLocalhost',
//   //   logo: 'customerB_logo.png',
//   // },
// };

// export const getThemeForDomain = (domain) => {
//   return themes[domain] || defaultTheme;
// };