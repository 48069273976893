import React, { useState, useEffect } from 'react';
import InvoiceModal from './InvoiceModal';

const sampleCustomers = [
  { name: 'Acme Corp', accountNumber: 'AC12345' },
  { name: 'Beta Industries', accountNumber: 'BI67890' },
  { name: 'Gamma LLC', accountNumber: 'GL13579' },
  { name: 'Delta Co.', accountNumber: 'DC24680' },
  { name: 'Epsilon Services', accountNumber: 'ES13579' },
];

const samplePaymentTerms = ['Net 15', 'Net 30', 'Net 60'];

const generateSampleInvoices = () => {
  return [
    {
      id: 1,
      invoiceNumber: 'INV-001',
      customer: 'Acme Corp',
      accountNumber: 'AC12345',
      invoiceDate: '2024-08-01',
      dueDate: '2024-08-16',
      paymentTerms: 'Net 15',
      lineItems: [
        { product: 'Cleanboost CB Gold', quantity: 500, unitPrice: 2.5, total: 1250 },
        { product: 'Diesel #2', quantity: 2000, unitPrice: 2.0, total: 4000 },
      ],
      totalAmount: 5250,
      status: 'Open',
    },
    {
      id: 2,
      invoiceNumber: 'INV-002',
      customer: 'Beta Industries',
      accountNumber: 'BI67890',
      invoiceDate: '2024-07-20',
      dueDate: '2024-08-04',
      paymentTerms: 'Net 15',
      lineItems: [
        { product: 'Syngear FG Series', quantity: 300, unitPrice: 4.0, total: 1200 },
      ],
      totalAmount: 1200,
      status: 'Paid',
    },
    // ... (More sample invoices up to 10)
  ];
};

const InvoicesComponent = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newInvoice, setNewInvoice] = useState({
    invoiceNumber: '',
    customer: '',
    accountNumber: '',
    invoiceDate: '',
    dueDate: '',
    paymentTerms: '',
    lineItems: [],
    totalAmount: 0,
    deliveryFee: 0,
    includeTaxes: false,
  });
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showQBModal, setShowQBModal] = useState(false);

  useEffect(() => {
    const storedInvoices = JSON.parse(localStorage.getItem('invoices')) || [];
    setInvoices(storedInvoices);
    setFilteredInvoices(storedInvoices);

    const storedOrders = JSON.parse(localStorage.getItem('orders')) || [];
    setOrders(storedOrders);
  }, []);

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    if (status === 'All') {
      setFilteredInvoices(invoices);
    } else {
      const filtered = invoices.filter(invoice => invoice.status === status);
      setFilteredInvoices(filtered);
    }
  };

  const viewInvoiceDetails = (invoice) => {
    setSelectedInvoice(invoice);
    setShowInvoiceModal(true);
  };

  const handleCreateInvoice = () => {
    setSelectedOrder(null);
    setNewInvoice({
      invoiceNumber: '',
      customer: '',
      accountNumber: '',
      invoiceDate: '',
      dueDate: '',
      paymentTerms: '',
      lineItems: [],
      totalAmount: 0,
      deliveryFee: 0,
      includeTaxes: false,
    });
    setShowCreateInvoiceModal(true);
  };

  const handleSelectOrder = (order) => {
    setSelectedOrder(order);
    setNewInvoice({
      invoiceNumber: `INV-${Math.floor(Math.random() * 1000000)}`,
      customer: order.customer,
      accountNumber: order.accountNumber,
      invoiceDate: new Date().toISOString().split('T')[0],
      dueDate: calculateDueDate('Net 15'), // Example term
      paymentTerms: 'Net 15',
      lineItems: order.lineItems.map(item => ({ ...item, unitPrice: 0, total: 0 })),
      totalAmount: 0,
      deliveryFee: 0,
      includeTaxes: false,
    });
  };

  const handleCustomerChange = (customerName) => {
    const customer = sampleCustomers.find(c => c.name === customerName);
    setNewInvoice({
      ...newInvoice,
      customer: customerName,
      accountNumber: customer ? customer.accountNumber : '',
    });
  };

  const calculateDueDate = (paymentTerms) => {
    const daysToAdd = parseInt(paymentTerms.split(' ')[1], 10);
    return new Date(Date.now() + daysToAdd * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
  };

  const handleLineItemChange = (index, field, value) => {
    const updatedLineItems = [...newInvoice.lineItems];
    updatedLineItems[index][field] = value;
    updatedLineItems[index].total = updatedLineItems[index].quantity * (updatedLineItems[index].unitPrice || 0);
    
    let updatedTotalAmount = updatedLineItems.reduce((sum, item) => sum + item.total, 0);

    if (newInvoice.includeTaxes) {
      updatedTotalAmount *= 1.1; // Example tax rate of 10%
    }

    if (newInvoice.deliveryFee) {
      updatedTotalAmount += newInvoice.deliveryFee;
    }

    setNewInvoice({
      ...newInvoice,
      lineItems: updatedLineItems,
      totalAmount: updatedTotalAmount,
    });
  };

  const handleCreateInvoiceSubmit = () => {
    const updatedInvoices = [...invoices, newInvoice];
    setInvoices(updatedInvoices);
    setFilteredInvoices(updatedInvoices);
    localStorage.setItem('invoices', JSON.stringify(updatedInvoices));
    setShowCreateInvoiceModal(false);
  };

  const generateSampleData = () => {
    const sampleInvoices = generateSampleInvoices();
    setInvoices(sampleInvoices);
    setFilteredInvoices(sampleInvoices);
    localStorage.setItem('invoices', JSON.stringify(sampleInvoices));
  };

  const clearSampleData = () => {
    setInvoices([]);
    setFilteredInvoices([]);
    localStorage.removeItem('invoices');
  };

  const addLineItem = () => {
    setNewInvoice({
      ...newInvoice,
      lineItems: [...newInvoice.lineItems, { product: '', quantity: 1, unitPrice: 0, total: 0 }],
    });
  };

  const handleInvoiceSelection = (invoiceId) => {
    setSelectedInvoices((prevSelected) => {
      if (prevSelected.includes(invoiceId)) {
        return prevSelected.filter(id => id !== invoiceId);
      } else {
        return [...prevSelected, invoiceId];
      }
    });
  };

  const handleExportToQB = () => {
    setShowQBModal(true);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Invoices Management</h2>
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleCreateInvoice}
          >
            Create Invoice
          </button>
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={generateSampleData}
          >
            Generate Sample Data
          </button>
          <button 
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={clearSampleData}
          >
            Clear Sample Data
          </button>
          <button 
            className="bg-green-500 text-white px-4 py-2 rounded"
            onClick={handleExportToQB}
            disabled={selectedInvoices.length === 0}
          >
            Export to QuickBooks
          </button>
        </div>
      </div>

      <div className="flex space-x-2 mb-4">
        <button 
          className={`px-4 py-2 rounded ${selectedStatus === 'All' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          onClick={() => handleStatusFilter('All')}
        >
          All
        </button>
        <button 
          className={`px-4 py-2 rounded ${selectedStatus === 'Open' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          onClick={() => handleStatusFilter('Open')}
        >
          Open
        </button>
        <button 
          className={`px-4 py-2 rounded ${selectedStatus === 'Paid' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          onClick={() => handleStatusFilter('Paid')}
        >
          Paid
        </button>
        <button 
          className={`px-4 py-2 rounded ${selectedStatus === 'Overdue' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          onClick={() => handleStatusFilter('Overdue')}
        >
          Overdue
        </button>
      </div>

      <table className="w-full border border-gray-300 mb-6">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Select</th>
            <th className="border border-gray-300 p-2">Invoice Number</th>
            <th className="border border-gray-300 p-2">Customer</th>
            <th className="border border-gray-300 p-2">Amount</th>
            <th className="border border-gray-300 p-2">Status</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredInvoices.map(invoice => (
            <tr key={invoice.id}>
              <td className="border border-gray-300 p-2 text-center">
                <input
                  type="checkbox"
                  checked={selectedInvoices.includes(invoice.id)}
                  onChange={() => handleInvoiceSelection(invoice.id)}
                />
              </td>
              <td className="border border-gray-300 p-2">{invoice.invoiceNumber}</td>
              <td className="border border-gray-300 p-2">{invoice.customer}</td>
              <td className="border border-gray-300 p-2">${invoice.totalAmount.toFixed(2)}</td>
              <td className="border border-gray-300 p-2">{invoice.status}</td>
              <td className="border border-gray-300 p-2">
                <button 
                  className="bg-blue-500 text-white px-2 py-1 rounded"
                  onClick={() => viewInvoiceDetails(invoice)}
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showInvoiceModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-2/3 max-h-screen overflow-y-auto">
            <InvoiceModal 
              invoice={selectedInvoice}
              onClose={() => setShowInvoiceModal(false)}
            />
            <button
              className="bg-red-500 text-white px-4 py-2 rounded mt-4"
              onClick={() => setShowInvoiceModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showCreateInvoiceModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-2/3 max-h-screen overflow-y-auto">
            <div className="mb-4">
              <h2 className="text-xl font-bold mb-4">Create New Invoice</h2>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Select Order</label>
                <select
                  className="border rounded w-full py-2 px-3"
                  value={selectedOrder ? selectedOrder.id : ''}
                  onChange={(e) => handleSelectOrder(orders.find(order => order.id === parseInt(e.target.value, 10)))}
                >
                  <option value="">None</option>
                  {orders.filter(order => order.status === 'Completed').map(order => (
                    <option key={order.id} value={order.id}>
                      {order.customer} - Order #{order.id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Customer</label>
                <select
                  className="border rounded w-full py-2 px-3"
                  value={newInvoice.customer}
                  onChange={(e) => handleCustomerChange(e.target.value)}
                >
                  <option value="">Select Customer</option>
                  {sampleCustomers.map(customer => (
                    <option key={customer.accountNumber} value={customer.name}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Account Number</label>
                <input 
                  type="text" 
                  className="border rounded w-full py-2 px-3" 
                  value={newInvoice.accountNumber} 
                  readOnly
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Invoice Date</label>
                <input 
                  type="date" 
                  className="border rounded w-full py-2 px-3" 
                  value={newInvoice.invoiceDate} 
                  onChange={(e) => setNewInvoice({ ...newInvoice, invoiceDate: e.target.value })}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Payment Terms</label>
                <select
                  className="border rounded w-full py-2 px-3"
                  value={newInvoice.paymentTerms}
                  onChange={(e) => {
                    const paymentTerms = e.target.value;
                    setNewInvoice({
                      ...newInvoice,
                      paymentTerms,
                      dueDate: calculateDueDate(paymentTerms),
                    });
                  }}
                >
                  <option value="">Select Payment Terms</option>
                  {samplePaymentTerms.map(term => (
                    <option key={term} value={term}>{term}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Due Date</label>
                <input 
                  type="date" 
                  className="border rounded w-full py-2 px-3" 
                  value={newInvoice.dueDate} 
                  readOnly
                />
              </div>

              <table className="w-full border border-gray-300 mb-6">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border border-gray-300 p-2">Product</th>
                    <th className="border border-gray-300 p-2">Quantity</th>
                    <th className="border border-gray-300 p-2">Unit Price</th>
                    <th className="border border-gray-300 p-2">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {newInvoice.lineItems.map((item, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 p-2">{item.product}</td>
                      <td className="border border-gray-300 p-2">{item.quantity}</td>
                      <td className="border border-gray-300 p-2">
                        <input 
                          type="number" 
                          className="border rounded w-full py-1 px-2" 
                          value={item.unitPrice || ''} 
                          onChange={(e) => handleLineItemChange(index, 'unitPrice', parseFloat(e.target.value))}
                        />
                      </td>
                      <td className="border border-gray-300 p-2">${item.total.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button className="bg-blue-500 text-white px-4 py-2 rounded mb-4" onClick={addLineItem}>
                Add Line Item
              </button>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={newInvoice.includeTaxes}
                    onChange={(e) => setNewInvoice({
                      ...newInvoice,
                      includeTaxes: e.target.checked,
                      totalAmount: e.target.checked ? newInvoice.totalAmount * 1.1 : newInvoice.totalAmount / 1.1,
                    })}
                  />
                  Include Taxes (10%)
                </label>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Delivery Fee</label>
                <input 
                  type="number" 
                  className="border rounded w-full py-2 px-3" 
                  value={newInvoice.deliveryFee} 
                  onChange={(e) => setNewInvoice({
                    ...newInvoice,
                    deliveryFee: parseFloat(e.target.value),
                    totalAmount: newInvoice.totalAmount + parseFloat(e.target.value),
                  })}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Total Amount</label>
                <input 
                  type="text" 
                  className="border rounded w-full py-2 px-3" 
                  value={`$${newInvoice.totalAmount.toFixed(2)}`} 
                  readOnly
                />
              </div>

              <button 
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleCreateInvoiceSubmit}
              >
                Submit Invoice
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mt-4"
                onClick={() => setShowCreateInvoiceModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showQBModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-2/3 max-h-screen overflow-y-auto">
            <h2 className="text-xl font-bold mb-4">Map Customers to QuickBooks</h2>
            <table className="w-full border border-gray-300 mb-6">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 p-2">Customer</th>
                  <th className="border border-gray-300 p-2">Mapping</th>
                  <th className="border border-gray-300 p-2">Create Customer in QB</th>
                </tr>
              </thead>
              <tbody>
                {selectedInvoices.map(invoiceId => {
                  const invoice = invoices.find(inv => inv.id === invoiceId);
                  return (
                    <tr key={invoice.id}>
                      <td className="border border-gray-300 p-2">{invoice.customer}</td>
                      <td className="border border-gray-300 p-2">
                        <select className="border rounded w-full py-2 px-3">
                          <option value="">Select QB Customer</option>
                          {/* Add QB customers options here */}
                        </select>
                      </td>
                      <td className="border border-gray-300 p-2 text-center">
                        <button className="bg-blue-500 text-white px-4 py-2 rounded">
                          Create in QB
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={() => setShowQBModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoicesComponent;
