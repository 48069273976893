import React, { useState, useEffect } from 'react';

const generateSampleProducts = () => {
  const sampleProducts = [
    { id: 1, name: 'Cleanboost CB Gold', quantity: 1000, unit: 'Gallons' },
    { id: 2, name: 'DEF - Diesel Exhaust Fluid', quantity: 5000, unit: 'Gallons' },
    { id: 3, name: 'Antifreeze/Coolant', quantity: 200, unit: 'Drums' },
    { id: 4, name: 'Syngear FG Series', quantity: 150, unit: 'Liters' },
    { id: 5, name: 'Varnasolv FG', quantity: 100, unit: 'Liters' },
    { id: 6, name: 'Diesel #2', quantity: 10000, unit: 'Gallons' },
    { id: 7, name: 'Diesel #1 (Winter Diesel)', quantity: 8000, unit: 'Gallons' },
    { id: 8, name: 'Gasoline - Regular', quantity: 12000, unit: 'Gallons' },
    { id: 9, name: 'Gasoline - Premium', quantity: 9000, unit: 'Gallons' },
    { id: 10, name: 'Syngear FG Series - High Temp', quantity: 300, unit: 'Liters' },
    { id: 11, name: 'HyPar FG Hydraulic Oil', quantity: 250, unit: 'Liters' },
    { id: 12, name: 'EnviroTech CO FG Chain Oil', quantity: 400, unit: 'Liters' },
    { id: 13, name: 'RHT FG-68 Ammonia Service Oil', quantity: 180, unit: 'Liters' },
    { id: 14, name: 'Seamer Oil 150 P', quantity: 600, unit: 'Liters' },
    { id: 15, name: 'Lubeair FG-22 Air Tool Oil', quantity: 500, unit: 'Liters' },
    { id: 16, name: 'WO-90 Food Grade White Oil', quantity: 700, unit: 'Liters' },
    { id: 17, name: 'Barrier Fluid E', quantity: 300, unit: 'Liters' },
    { id: 18, name: 'HySyn FG Series', quantity: 500, unit: 'Liters' },
    { id: 19, name: 'Sumtech FGCS Series', quantity: 350, unit: 'Liters' },
    { id: 20, name: 'SBL Food Grade Series', quantity: 400, unit: 'Liters' },
  ];
  return sampleProducts;
};

const units = ['Gallons', 'Liters', 'Drums', 'Cases', 'Pails', 'Bottles'];

const InventoryComponent = () => {
  const [products, setProducts] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [updatedQuantity, setUpdatedQuantity] = useState('');
  const [updatedUnit, setUpdatedUnit] = useState('');
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [newProductName, setNewProductName] = useState('');
  const [newProductQuantity, setNewProductQuantity] = useState('');
  const [newProductUnit, setNewProductUnit] = useState(units[0]);
  const [showAddInventoryModal, setShowAddInventoryModal] = useState(false);
  const [inventoryProductId, setInventoryProductId] = useState(null);
  const [addQuantity, setAddQuantity] = useState('');

  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('products'));
    if (storedProducts) {
      setProducts(storedProducts);
    }
  }, []);

  const handleUpdateProduct = (id) => {
    const updatedProducts = products.map(product => 
      product.id === id 
        ? { ...product, quantity: updatedQuantity, unit: updatedUnit }
        : product
    );
    setProducts(updatedProducts);
    localStorage.setItem('products', JSON.stringify(updatedProducts));
    setEditingProductId(null);
  };

  const handleAddProduct = (e) => {
    e.preventDefault();
    const newProduct = {
      id: products.length + 1,
      name: newProductName,
      quantity: newProductQuantity,
      unit: newProductUnit
    };
    const updatedProducts = [...products, newProduct];
    setProducts(updatedProducts);
    localStorage.setItem('products', JSON.stringify(updatedProducts));
    setShowAddProductModal(false);
    setNewProductName('');
    setNewProductQuantity('');
    setNewProductUnit(units[0]);
  };

  const handleAddToInventory = (id) => {
    const updatedProducts = products.map(product => 
      product.id === id 
        ? { ...product, quantity: parseInt(product.quantity) + parseInt(addQuantity) }
        : product
    );
    setProducts(updatedProducts);
    localStorage.setItem('products', JSON.stringify(updatedProducts));
    setShowAddInventoryModal(false);
    setAddQuantity('');
  };

  const generateData = () => {
    const sampleProducts = generateSampleProducts();
    setProducts(sampleProducts);
    localStorage.setItem('products', JSON.stringify(sampleProducts));
  };

  const clearData = () => {
    setProducts([]);
    localStorage.removeItem('products');
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Inventory Management</h2>
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded" 
            onClick={generateData}
          >
            Generate Sample Data
          </button>
          <button 
            className="bg-red-500 text-white px-4 py-2 rounded" 
            onClick={clearData}
          >
            Clear Data
          </button>
          <button 
            className="bg-green-500 text-white px-4 py-2 rounded" 
            onClick={() => setShowAddProductModal(true)}
          >
            Add Product
          </button>
        </div>
      </div>

      <table className="w-full border border-gray-300 mb-6">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Product Name</th>
            <th className="border border-gray-300 p-2">Quantity</th>
            <th className="border border-gray-300 p-2">Unit</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td className="border border-gray-300 p-2">{product.name}</td>
              <td className="border border-gray-300 p-2">
                {editingProductId === product.id ? (
                  <input 
                    type="number" 
                    className="border rounded w-full py-1 px-2" 
                    value={updatedQuantity} 
                    onChange={(e) => setUpdatedQuantity(e.target.value)}
                  />
                ) : (
                  product.quantity
                )}
              </td>
              <td className="border border-gray-300 p-2">
                {editingProductId === product.id ? (
                  <select 
                    className="border rounded w-full py-1 px-2" 
                    value={updatedUnit} 
                    onChange={(e) => setUpdatedUnit(e.target.value)}
                  >
                    {units.map((unit, index) => (
                      <option key={index} value={unit}>{unit}</option>
                    ))}
                  </select>
                ) : (
                  product.unit
                )}
              </td>
              <td className="border border-gray-300 p-2">
                {editingProductId === product.id ? (
                  <button 
                    className="bg-green-500 text-white px-4 py-2 rounded"
                    onClick={() => handleUpdateProduct(product.id)}
                  >
                    Save
                  </button>
                ) : (
                  <>
                    <button 
                      className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                      onClick={() => {
                        setEditingProductId(product.id);
                        setUpdatedQuantity(product.quantity);
                        setUpdatedUnit(product.unit);
                      }}
                    >
                      Edit
                    </button>
                    <button 
                      className="bg-yellow-500 text-white px-4 py-2 rounded"
                      onClick={() => {
                        setShowAddInventoryModal(true);
                        setInventoryProductId(product.id);
                      }}
                    >
                      Add to Inventory
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add Product Modal */}
      {showAddProductModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Add New Product</h2>
            <form onSubmit={handleAddProduct}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Product Name *</label>
                <input 
                  type="text" 
                  className="border rounded w-full py-2 px-3" 
                  value={newProductName}
                  onChange={(e) => setNewProductName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Quantity *</label>
                <input 
                  type="number" 
                  className="border rounded w-full py-2 px-3" 
                  value={newProductQuantity}
                  onChange={(e) => setNewProductQuantity(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Unit *</label>
                <select 
                  className="border rounded w-full py-2 px-3" 
                  value={newProductUnit}
                  onChange={(e) => setNewProductUnit(e.target.value)}
                  required
                >
                  {units.map((unit, index) => (
                    <option key={index} value={unit}>{unit}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end">
                <button 
                  type="button" 
                  className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                  onClick={() => setShowAddProductModal(false)}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Add Product
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Add to Inventory Modal */}
      {showAddInventoryModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Add to Inventory</h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleAddToInventory(inventoryProductId);
            }}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Quantity to Add *</label>
                <input 
                  type="number" 
                  className="border rounded w-full py-2 px-3" 
                  value={addQuantity}
                  onChange={(e) => setAddQuantity(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button 
                  type="button" 
                  className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                  onClick={() => setShowAddInventoryModal(false)}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Add to Inventory
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryComponent;
