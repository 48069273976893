import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import MobileHeader from './MobileHeader';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const apiUrl = process.env.REACT_APP_API_URL;

const CardEditV2 = () => {
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const cardNumber = location.state?.cardNumber || "";
  const policies = useSelector((state) => state.policies.policies);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("success");
  const [subfleetFromParent, setSubfleetFromParent] = useState(false);
  const [formData, setFormData] = useState({
    CardNumber: "",
    DriverId: "",
    DriverName: "",
    UnitNumber: "",
    CardStatus: "",
    CardSubfleet: "",
    PolicyNumber: ""
  });
  const [limits, setLimits] = useState([
    { LimitId: 'DSL', LimitValue: 250 },
    { LimitId: 'RFR', LimitValue: 0 },
    { LimitId: 'DEF', LimitValue: 0 },
    { LimitId: 'OIL', LimitValue: 0 },
    { LimitId: 'ADD', LimitValue: 0 },
    { LimitId: 'ANFR', LimitValue: 0 },
    { LimitId: 'BDSL', LimitValue: 0 },
    { LimitId: 'DEFC', LimitValue: 0 },
    { LimitId: 'DELI', LimitValue: 0 },
    { LimitId: 'GAS', LimitValue: 0 },
    { LimitId: 'GROC', LimitValue: 0 },
    { LimitId: 'LMPR', LimitValue: 0 },
    { LimitId: 'MERC', LimitValue: 0 },
    { LimitId: 'OILC', LimitValue: 0 },
    { LimitId: 'PART', LimitValue: 0 },
    { LimitId: 'PROP', LimitValue: 0 },
    { LimitId: 'REST', LimitValue: 0 },
    { LimitId: 'SCLE', LimitValue: 0 },
    { LimitId: 'SHWR', LimitValue: 0 },
    { LimitId: 'TIRE', LimitValue: 0 },
    { LimitId: 'REPR', LimitValue: 0 },
    { LimitId: 'WASH', LimitValue: 0 },
    { LimitId: 'WWFL', LimitValue: 0 }
  ]);

  const [errors, setErrors] = useState({});

  const clearAlert = () => setAlertMessage(null);

  useEffect(() => {
    if (!cardNumber) {
      navigate(-1);
      return;
    }

    const fetchCardDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: 'getCardDetails',
            payload: {
              cardNumber,
              userId: currentUser.userId
            }
          }),
        });

        const data = await response.json();
        console.log("Card Data: ", data);
        setFormData({
          CardNumber: data.CardNumber,
          DriverId: data.DriverId || "",
          DriverName: data.DriverName || "",
          UnitNumber: data.UnitNumber || "",
          CardStatus: data.Status || "",
          CardSubfleet: data.CardSubfleet || "",
          PolicyNumber: data.PolicyNumber || ""
        });

        if (data.Limits && data.Limits.length > 0) {
          const mergedLimits = limits.map(limit => {
            const fetchedLimit = data.Limits.find(l => l.LimitId === limit.LimitId);
            return fetchedLimit ? { ...limit, LimitValue: fetchedLimit.LimitValue } : limit;
          });
          setLimits(mergedLimits);
        }
        setSubfleetFromParent(data.SubfleetFromParent);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchCardDetails();
  }, [cardNumber, navigate, currentUser.userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLimitChange = (e, limitId) => {
    const { value } = e.target;
    const numericValue = Number(value);
    setLimits((prevLimits) => {
      const updatedLimits = prevLimits.map((limit) =>
        limit.LimitId === limitId ? { ...limit, LimitValue: numericValue } : limit
      );
      console.log('Updated Limits: ', updatedLimits); // Log the updated limits
      return updatedLimits;
    });
  };

  useEffect(() => {
    console.log('Updated Form Data : ', formData);
  }, [formData]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.CardStatus) {
      newErrors.CardStatus = 'Card Status is required';
    }

    if (formData.DriverId && formData.DriverId.length > 24) {
      newErrors.DriverId = 'Driver ID must be 24 characters or less';
    }

    if (!formData.DriverName) {
      newErrors.DriverName = 'Driver Name is required';
    }

    if (formData.UnitNumber.length > 24) {
      newErrors.UnitNumber = 'Unit Number must be 24 characters or less';
    }

    const invalidLimits = limits.filter(limit => limit.LimitValue < 0 || limit.LimitValue > 9999);
    if (invalidLimits.length > 0) {
      newErrors.Limits = 'Limits must be between 0 and 9999';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      const filteredLimits = limits.filter(limit => limit.LimitValue > 0);  // Exclude limits with value 0

      const response = await fetch(`${apiUrl}/dataServer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: 'updateCardDetails',
          payload: {
            cardData: { ...formData, Limits: filteredLimits },
            userId: currentUser.userId
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("subit result: ", result);
      if (result.message == 'Successfully updated card!') {
        setAlertType("success");
        setAlertMessage("Successfully updated the card!");
      } else {
        throw new Error(result.message || "Failed to update card data");
      }
    } catch (error) {
      setAlertType("error");
      setAlertMessage(`Failed to update card data: ${error.message}`);
      console.error('Submission failed', error);
    } finally {
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const limitFields = [
    { label: "Diesel", unit: "Gallons", id: "DSL" },
    { label: "Reefer", unit: "Gallons", id: "RFR" },
    { label: "DEF Pump", unit: "Gallons", id: "DEF" },
    // { label: "Oil", unit: "Dollars", id: "OIL" },
    // { label: "Additives", unit: "Dollars", id: "ADD" },
    // { label: "Antifreeze/Coolant", unit: "Dollars", id: "ANFR" },
    // { label: "Biodiesel", unit: "Gallons", id: "BDSL" },
    // { label: "DEF Container", unit: "Dollars", id: "DEFC" },
    // { label: "Deli", unit: "Dollars", id: "DELI" },
    // { label: "Gasoline", unit: "Gallons", id: "GAS" },
    // { label: "Groceries", unit: "Dollars", id: "GROC" },
    // { label: "Lumper", unit: "Dollars", id: "LMPR" },
    // { label: "Miscellaneous", unit: "Dollars", id: "MERC" },
    // { label: "Oil Change", unit: "Dollars", id: "OILC" },
    // { label: "Parts", unit: "Dollars", id: "PART" },
    // { label: "Propane", unit: "Gallons", id: "PROP" },
    // { label: "Restaurant", unit: "Dollars", id: "REST" },
    // { label: "Scales", unit: "Dollars", id: "SCLE" },
    // { label: "Shower", unit: "Dollars", id: "SHWR" },
    // { label: "Tire Purchase", unit: "Dollars", id: "TIRE" },
    // { label: "Truck Repair", unit: "Dollars", id: "REPR" },
    // { label: "Truck Wash", unit: "Dollars", id: "WASH" },
    // { label: "Wiper Fluid", unit: "Dollars", id: "WWFL" },
  ];

  return (
    <div className="mobile-container mx-auto lg:px-8">
      <MobileHeader theme={theme} />
      {isLoading && <Spinner />}
      <div className="text-end mb-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-gray-700 text-sm mr-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
          Go Back
        </button>
      </div>
      {alertMessage && (
        <div
          className={`alert p-4 rounded ${alertType === "success"
            ? "bg-green-200 text-green-600"
            : "bg-red-200 text-red-600"
            } flex justify-between items-center`}
        >
          {alertMessage}
          <button onClick={clearAlert} className="text-lg font-bold">
            ×
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4 m-8">


        <fieldset className="border p-4 rounded">
          <legend className="text-lg font-medium text-gray-700">Card Information</legend>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Card Number
            </label>
            <input
              type="text"
              name="CardNumber"
              value={formData.CardNumber}
              className="mt-1 p-2 w-full border rounded-md"
              readOnly
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Card Status
            </label>
            <select
              name="CardStatus"
              onChange={handleChange}
              value={formData.CardStatus}
              className="mt-1 p-2 w-full border rounded-md"
              required
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="HOLD">Hold</option>
            </select>
            {errors.CardStatus && (
              <p className="text-red-500 text-xs mt-1">{errors.CardStatus}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Driver ID
            </label>
            <input
              type="text"
              name="DriverId"
              onChange={handleChange}
              value={formData.DriverId}
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Leave blank if you do not wish to use this value"
              maxLength={24}
            />
            <span className="text-sm text-gray-500 mt-1 block">This value will be requested at the pump and must match. (Recommended)</span>
            {errors.DriverId && (
              <p className="text-red-500 text-xs mt-1">{errors.DriverId}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Driver Name
            </label>
            <input
              type="text"
              name="DriverName"
              onChange={handleChange}
              value={formData.DriverName}
              className="mt-1 p-2 w-full border rounded-md"
              required
              maxLength={24}
            />
            {errors.DriverName && (
              <p className="text-red-500 text-xs mt-1">{errors.DriverName}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Unit #
            </label>
            <input
              type="text"
              name="UnitNumber"
              onChange={handleChange}
              value={formData.UnitNumber}
              className="mt-1 p-2 w-full border rounded-md"
              required
              maxLength={24}
            />
            <span className="text-sm text-gray-500 mt-1 block">This value will be requested at the pump and must match. (Required)</span>
            {errors.UnitNumber && (
              <p className="text-red-500 text-xs mt-1">{errors.UnitNumber}</p>
            )}
          </div>

          {(currentUser.isAdmin || currentUser.isContractAdmin || currentUser.isPolicyAdmin) && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Subfleet ID
              </label>
              <input
                type="text"
                name="CardSubfleet"
                onChange={handleChange}
                value={formData.CardSubfleet}
                className="mt-1 p-2 w-full border rounded-md bg-white"
                disabled={subfleetFromParent}
              />

              {subfleetFromParent && (
                <span className="text-sm text-red-500 mt-1 block">
                  Subfleet ID is set from the account. To edit, update it in the <a href="/cardgroups" className="text-blue-500 underline">Account Settings</a>.
                </span>
              )}
            </div>
          )}
          {(currentUser.isAdmin || currentUser.isContractAdmin) && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Policy#
              </label>
              <select
                name="PolicyNumber"
                onChange={handleChange}
                value={formData.PolicyNumber || ""}
                className="mt-1 p-2 w-full border rounded-md"
              >
                <option value="" disabled={!formData.PolicyNumber}>
                  Select a policy
                </option>
                {policies &&
                  policies.map((policy) => (
                    <option key={policy.ID} value={policy.PolicyNumber}>
                      {policy.PolicyNumber} - {policy.Description}
                    </option>
                  ))}
              </select>
              {errors.PolicyNumber && (
                <p className="text-red-500 text-xs mt-1">{errors.PolicyNumber}</p>
              )}
            </div>
          )}
        </fieldset>

        <fieldset className="border p-4 rounded mt-4">
          <legend className="text-lg font-medium text-gray-700">Daily Limits</legend>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {limitFields.map((limitField) => (
              <div key={limitField.id}>
                <label className="block text-sm font-medium text-gray-700">
                  {limitField.label} ({limitField.unit})
                </label>
                <input
                  type="number"
                  name={limitField.id}
                  value={limits.find(limit => limit.LimitId === limitField.id)?.LimitValue || ''}
                  onChange={(e) => handleLimitChange(e, limitField.id)}
                  className="mt-1 p-2 w-full border rounded-md"
                  min="0"
                  max="9999"
                  step="1"

                />
              </div>
            ))}
          </div>
          {errors.Limits && (
            <p className="text-red-500 text-xs mt-1">{errors.Limits}</p>
          )}
        </fieldset>
        <button
          type="submit"
          className="mt-4 px-4 py-2 text-white rounded themed-button"
        >
          Update Card
        </button>
      </form>
      <div className="text-end">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-gray-700 text-sm mr-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
          Go Back
        </button>
      </div>
    </div>
  );
};

export default CardEditV2;
