//We are setting the idle time in app.js and using twice the value to logout the user at onPrompt.

import { useContext, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { AuthContext } from "./AuthContext"; // Adjust the path as necessary

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
    const idleTimeout = 1000 * 60 * idleTime; // Convert minutes to milliseconds
    const promptBeforeIdle = idleTimeout / 2; // Prompt the user halfway through the idle time

    const [isIdle, setIdle] = useState(false);
    const { logout } = useContext(AuthContext);

    const handleIdle = () => {
        setIdle(true);
        logout();
    };

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: promptBeforeIdle, 
        onPrompt: onIdle, // This will be triggered before the user is considered idle
        onIdle: handleIdle, // This will be triggered when the user is considered idle
        debounce: 500
    });

    return {
        isIdle,
        setIdle,
        idleTimer
    };
};


export default useIdleTimeout;
