import React from 'react';
import { useNavigate } from 'react-router-dom';

const getFAQs = (subdomain) => {
  const faqs = [
    {
      category: "General Questions",
      questions: [
        { q: "How do I sign up?", a: <span>You can sign up by completing the <a href="/registration" className="text-blue-500 underline">registration form.</a></span> },
        { q: "What information is required to sign up?", a: "You will need to provide your name, phone, email, mailing address and provide a payment method for funding your account." },
        { q: "Is my data secure?", a: "Yes, your data is securely processed via encrypted ACH transfer." },
        { q: "Is there an approval process?", a: "There is no approval process; all fuel cards are pre-paid." },
        { q: "Where can I use my card?", a: <span>You can use your card at over 4,000 locations nationwide. Visit our <a href="/fuelinglocations" className="text-blue-500 underline">locations page</a> for more details.</span> },
        { q: "How much will I save using my card?", a: subdomain === 'aqha' ? "You will save $0.15 per gallon." : "Savings vary based on network discounts." },
        ...(subdomain === 'aqha' ? [{ q: "How will using my card benefit initiatives?", a: "Programs will receive an additional $0.05 per gallon donation." }] : []),
      ],
    },
    {
      category: "Card Questions",
      questions: [
        { q: "When will I receive my card?", a: "Your card will arrive in 7 to 10 days by mail." },
        { q: "What should I do if I lose my card?", a: <span>Immediately mark the card as inactive and contact us at <a href="mailto:support@fuellink.net" className="text-blue-500 underline">support@fuellink.net</a> to order a replacement.</span> },
        { q: "What should I do if my card does not work at the pump?", a: "Check for physical damage and request a replacement if needed. Ensure that sufficient funds are available for fuel purchase." },
        { q: "How can I check my available balance?", a: "You can check your balance on the fuel portal." },
        { q: "How can I increase my available balance?", a: "You can increase your balance via ACH transfer on the fuel portal." },
        { q: "Can I view my card history?", a: "Yes, you can view your card history in the reports tab on the fuel portal." },
        { q: "How many gallons can I purchase and how frequently?", a: "You can purchase up to the designated funds available on your card." },
        { q: "I need more assistance, who can I contact for help?", a: <span>You can contact support by email at <a href="mailto:support@fuellink.net" className="text-blue-500 underline">support@fuellink.net</a> or by calling (801) 828-6936.</span> },
      ],
    },
    {
      category: "Banking Questions",
      questions: [
        { q: "What banking information is required?", a: "You need to provide your bank account and routing number." },
        { q: "Is my banking data secure?", a: "Yes, your banking data is securely processed via encrypted ACH transfer." },
        { q: "How do you initially transfer money from my bank to my card?", a: "We use ACH to directly transfer money from your bank account to your card." },
        { q: "How are funds replenished after I purchase fuel?", a: <span>By default, your card is refilled to the desired balance set during account setup. When the balance reaches 40% of the desired amount, we will automatically draft the necessary funds to restore the balance. This feature can be disabled in the <a href="/settings" className="text-blue-500 underline">settings tab</a>.</span> },
        { q: "What does a successful ACH notification mean?", a: "A successful ACH notification means that your funds have been successfully posted to your fuel card account." },
        { q: "What does a failed ACH notification mean?", a: "A failed ACH notification indicates that there were insufficient funds in your bank account, and a $6.00 fee has been charged to your account." },
      ],
    },
  ];
  return faqs;
};

const FAQPage = () => {
  const subdomain = window.location.hostname.split('.')[0];
  const faqs = getFAQs(subdomain);
  const navigate = useNavigate();

  return (
    <div className="max-w-7xl mx-auto p-6 sm:p-10">
      
      <h1 className="text-4xl font-bold mb-8">Frequently Asked Questions</h1>
      <button
        onClick={() => navigate(-1)}
        className="themed-button py-2 px-4 mb-4"
      >
        Back
      </button>
      {faqs.map((section, index) => (
        <div key={index} className="mb-10">
          <h2 className="text-2xl font-semibold mb-4">{section.category}</h2>
          <div className="space-y-4">
            {section.questions.map((faq, i) => (
              <div key={i} className="bg-gray-100 p-4 rounded-md shadow-md">
                <h3 className="text-xl font-medium">{faq.q}</h3>
                <p className="mt-2 text-gray-700">{faq.a}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
      <button
        onClick={() => navigate(-1)}
        className="themed-button py-2 px-4"
      >
        Back
      </button>
    </div>
  );
};

export default FAQPage;
