import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import MainTableV2 from './MainTableV2';
import { useAuth } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from './PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import SalesRepModal from './SalesRepModal';
import Spinner from './Spinner';
import MobileHeader from './MobileHeader';

const UserTable = () => {
  const { theme } = useTheme();
  const primaryColor = theme.primary;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { currentUser } = useAuth();
  const clearAlert = () => setAlertMessage(null);
  const navigate = useNavigate();

  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [salesReps, setSalesReps] = useState([]);
  const [showAddRepModal, setShowAddRepModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminUserData', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: currentUser.userId, requestType: 'getUserDetails', subDomain: currentUser.subDomain })
        });

        if (response.ok) {
          const fetchedData = await response.json();
          console.log(fetchedData);
          setData(fetchedData.users || []);
          setSalesReps(fetchedData.salesReps || []);
        } else {
          setAlertMessage(`Server Error: ${await response.text()}`);
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const handleEditClick = (row) => {
    navigate(`/EditUserForm/${row.RecordId}`, { state: { rowData: row } });
  };

  const handleAssignClick = (user) => {
    setSelectedUser(user);
    setShowAssignModal(true);
  };

  const handleAssignRep = async (repId) => {
    setLoading(true);
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminUserData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: selectedUser.UserId,
          repId: repId,
          requestType: 'assignSalesRep'
        }),
      });

      if (response.ok) {
        const assignedRep = salesReps.find(rep => rep.Id === repId);
        setData(prevData =>
          prevData.map(user =>
            user.UserId === selectedUser.UserId ? { ...user, SalesRepId: repId, salesRepInfo: assignedRep } : user
          )
        );
        setShowAssignModal(false);
      } else {
        setAlertMessage(`Server Error: ${await response.text()}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRep = async (salesRep) => {
    setLoading(true);
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminUserData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          requestType: 'addSalesRep',
          subDomain: currentUser.subDomain,
          salesRep: salesRep,
          userId: currentUser.userId
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setSalesReps([...salesReps, result.salesRep]);
        setShowAddRepModal(false);
      } else {
        const errorText = await response.text();
        console.error(`Error adding sales rep: ${errorText}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEditRep = async (salesRep) => {
    setLoading(true);
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminUserData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          requestType: 'editSalesRep',
          salesRep: salesRep,
          userId: currentUser.userId
        }),
      });

      if (response.ok) {
        setSalesReps(prevSalesReps =>
          prevSalesReps.map(rep =>
            rep.Id === salesRep.Id ? salesRep : rep
          )
        );
        setData(prevData =>
          prevData.map(user =>
            user.SalesRepId === salesRep.Id ? { ...user, salesRepInfo: salesRep } : user
          )
        );
      } else {
        const errorText = await response.text();
        console.error(`Error editing sales rep: ${errorText}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRep = async (repToDelete, replaceWith) => {
    setLoading(true);
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminUserData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          salesRepId: repToDelete.Id, // Ensure the correct ID is being passed
          replaceWith: replaceWith,
          requestType: 'deleteSalesRep'
        }),
      });

      if (response.ok) {
        setSalesReps(prevSalesReps => prevSalesReps.filter(rep => rep.Id !== repToDelete.Id));
        setData(prevData =>
          prevData.map(user =>
            user.SalesRepId === repToDelete.Id ? { ...user, SalesRepId: replaceWith, salesRepInfo: replaceWith ? salesReps.find(rep => rep.Id === replaceWith) : null } : user
          )
        );
      } else {
        const errorText = await response.text();
        throw new Error(`Error deleting sales rep: ${errorText}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };


  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: row => `${row.FirstName} ${row.LastName}`,
      sortable: true,
      reorder: true,
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Company Name',
      selector: row => row.CompanyName,
      sortable: true,
      reorder: true,
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Email',
      selector: row => row.Email,
      sortable: true,
      reorder: true,
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Phone',
      selector: row => row.Phone,
      sortable: true,
      reorder: true,
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Location',
      selector: row => row.latestAgreement ? row.latestAgreement.LocationCityState : 'N/A',
      sortable: true,
      reorder: true,
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Assigned',
      cell: row => row.salesRepInfo ? (
        <>
          {`${row.salesRepInfo.FirstName} ${row.salesRepInfo.LastName}`}
          <FontAwesomeIcon icon={faEdit} className="ml-2 text-blue-500 cursor-pointer" onClick={() => handleAssignClick(row)} />
        </>
      ) : (
        <span
          onClick={() => handleAssignClick(row)}
          className="text-blue-500 cursor-pointer"
        >
          <FontAwesomeIcon icon={faPlus} /> Assign
        </span>
      ),
      sortable: true,
      reorder: true,
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Setup Complete',
      cell: row => row.SetupComplete ? (
        <FontAwesomeIcon icon={faCheck} className="text-green-500" />
      ) : (
        <FontAwesomeIcon icon={faExclamation} className="text-red-500" />
      ),
      ignoreRowClick: true,
      style: {
        width: '50px',
        whiteSpace: 'nowrap'
      }
    },
    {
      name: 'Edit',
      cell: row => (
        <button
          className="themed-button"
          onClick={() => handleEditClick(row)}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
    }
  ], [handleEditClick]);

  const filterConfig = [
    { key: 'FirstName', type: 'string' },
    { key: 'CompanyName', type: 'string' },
    { key: 'LastName', type: 'string' },
    { key: 'Email', type: 'string' },
    { key: 'Phone', type: 'string' },
    { key: 'latestAgreement.LocationCityState', type: 'string' },
    { key: 'salesRepInfo.FirstName', type: 'string' },
    { key: 'salesRepInfo.LastName', type: 'string' },
  ];

  return (
    <>
      <MobileHeader theme={theme} />
      <div className='mobile-container'>
        <PageHeader
          heading="User Management"
          context="Manage and administer user accounts and permissions."
        />

        <MainTableV2
          columns={columns}
          data={data}
          primaryColor={primaryColor}
          loading={loading}
          alertMessage={alertMessage}
          clearAlert={clearAlert}
          title="Manage Users"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filterConfig={filterConfig}
        >
          <div className="col-span-1"></div>  {/* Empty div for the first column */}
          <div className="col-span-1 flex justify-end">
            <Link className="themed-button" to="/adduser">Add New User</Link>
          </div>

        </MainTableV2>

        <SalesRepModal
          show={showAssignModal}
          onClose={() => setShowAssignModal(false)}
          onAdd={handleAddRep}
          onAssign={handleAssignRep}
          currentUser={currentUser}
          selectedUser={selectedUser}
          salesReps={salesReps}
          setSalesReps={setSalesReps}
          onEdit={handleEditRep}
          onDelete={handleDeleteRep}
          setUsers={setData}
        />
      </div>
    </>
  );
};

export default UserTable;
