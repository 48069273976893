import React, { useState, useEffect } from 'react';
import SubTableV2 from './SubTableV2';
import Spinner from './Spinner';
import { useAuth } from './AuthContext';

const AddUserModal = ({ isOpen, onClose, onUsersAdded, cardGroupId }) => {
    const { currentUser } = useAuth();
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [search, setSearch] = useState('');
    const apiUrl= process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (isOpen) {
            fetchUsers();
        }
    }, [isOpen]);

    const fetchUsers = async () => {
        setLoading(true);
        setError('');
        try {
            const payload = { userId: currentUser.userId };
            const response = await fetch(`${apiUrl}/dataServer`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ action: 'getUsersAdmin', payload })
        });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("User Data: ", data);
            setUsers(data);
        } catch (error) {
            setError(`Failed to fetch users: ${error.message}`);
            console.error('There was an error!', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredUsers = users.filter(user => {
        return (
            user.FirstName.toLowerCase().includes(search.toLowerCase()) ||
            user.LastName.toLowerCase().includes(search.toLowerCase()) ||
            user.Email.toLowerCase().includes(search.toLowerCase()) ||
            (user.Phone && user.Phone.includes(search))
        );
    });

    const handleSelectUser = (userId) => {
        const newSelectedUsers = selectedUsers.includes(userId)
            ? selectedUsers.filter(id => id !== userId)
            : [...selectedUsers, userId];
        setSelectedUsers(newSelectedUsers);
    };
    

    const columns = [
        {
            name: 'Select',
            selector: row => row.userId,
            cell: row => (
                <input
                    type="checkbox"
                    checked={selectedUsers.includes(row.UserId)}
                    onChange={() => handleSelectUser(row.UserId)}
                />
            ),
            ignoreRowClick: true
        },
        {
            name: 'First Name',
            selector: row => row.FirstName,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: row => row.LastName,
            sortable: true
        },
        {
            name: 'Phone',
            selector: row => row.Phone
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true
        },
        {
            name: 'Card Group ID',
            selector: row => row.cardGroupID || 'None'
        }
    ];

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const payload = {
                selectedUsers: selectedUsers,
                cardGroupId: cardGroupId,
                userId: currentUser.userId
            };

            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action: 'updateUsersCardGroup', payload })
            });

            if (!response.ok) {
                throw new Error(`Failed to assign users to group: ${response.statusText}`);
            }

            console.log('Payload: ', payload);

            const updatedUsers = users.filter(user => selectedUsers.includes(user.UserId));
           onUsersAdded(updatedUsers);
            onClose();
        } catch (error) {
            setError(`Request failed: ${error.message}`);
            console.error('Error during user group update:', error);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="mobile-container fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Add Users to Group</h3>
                {loading && <Spinner />}
                <div className="bg-white p-4 border border-gray-300 shadow mb-4 rounded-lg">
                    <input
                        type="text"
                        placeholder="Search users..."
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    {selectedUsers.length > 0 && (
                            <div className="text-sm font-medium text-gray-700 mt-2">
                                {selectedUsers.length} user(s) selected
                            </div>
                        )}
                </div>
                <SubTableV2
                    columns={columns}
                    data={filteredUsers}
                    loading={loading}
                    alertMessage={error}
                    clearAlert={() => setError('')}
                />
                <div className="flex justify-end space-x-2 mt-4">
                    <button onClick={handleSubmit} className="themed-button py-2 px-4 rounded">
                        Add Selected Users
                    </button>
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddUserModal;
