import React, { useState, useEffect } from 'react';
import PageHeader from './PageHeader';
import ToggleSwitch from './ToggleSwitch';
import MobileHeader from './MobileHeader';
import { useTheme } from './ThemeContext';
import Spinner from './Spinner';
import { useAuth } from './AuthContext';

const SettingsPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { currentUser } = useAuth();
  const userRoles = currentUser?.Roles || [];
  const [settings, setSettings] = useState({
    CompanyName: '',
    Email: '',
    Phone: '',
    MaintainBalance: false,
    MinFunds: 0,
  });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
  const { theme } = useTheme();
  const primaryColor = theme.primary;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/dataServer`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            action: 'getUserSettings',
            payload: { userId: currentUser?.userId },
          }),
        });

        if (response.ok) {
          const fetchedData = await response.json();
          setSettings(fetchedData);
        } else {
          setAlertMessage({ message: `Server Error: ${await response.text()}`, type: 'error' });
        }
      } catch (error) {
        setAlertMessage({ message: `Error: ${error.message}`, type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    if (currentUser?.userId) {
      fetchData();
    }
  }, [currentUser?.userId]);

  const handleToggleChange = () => {
    setSettings(prevSettings => ({
      ...prevSettings,
      MaintainBalance: !prevSettings.MaintainBalance,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'updateUserSettings',
          payload: { userId: currentUser?.userId, ...settings },
        }),
      });

      if (response.ok) {
        setAlertMessage({ message: 'Settings updated successfully.', type: 'success' });
      } else {
        setAlertMessage({ message: `Server Error: ${await response.text()}`, type: 'error' });
      }
    } catch (error) {
      setAlertMessage({ message: `Error: ${error.message}`, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const closeAlert = () => {
    setAlertMessage({ message: '', type: '' });
  };

  return (
    <>
      {loading && <Spinner />}
      <MobileHeader theme={theme} />
      <div className="mobile-container">
        <PageHeader heading="Settings" context="View and edit site settings." />
        {alertMessage.message && (
          <div className={`mb-4 p-4 rounded ${alertMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            <div className="flex justify-between items-center">
              <span>{alertMessage.message}</span>
              <button onClick={closeAlert} className="text-lg font-bold">&times;</button>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">User Settings</h2>
            <div className="mb-6">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="CompanyName">Company Name</label>
              <input
                type="text"
                id="CompanyName"
                name="CompanyName"
                value={settings.CompanyName}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                disabled={userRoles.includes('Driver')}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="Email">Email</label>
              <input
                type="email"
                id="Email"
                name="Email"
                value={settings.Email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
              <p className="text-sm text-gray-500 mt-1">Updating this value will not update your login information.</p>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="Phone">Phone</label>
              <input
                type="tel"
                id="Phone"
                name="Phone"
                value={settings.Phone}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>
          {!userRoles.includes('Driver') && (
            <div className="mb-10">
              <h2 className="text-2xl font-semibold mb-4">Financial Settings</h2>
              <div className="mb-6">
                <label className="block text-gray-700 font-medium mb-2">Auto Reload</label>
                <ToggleSwitch
                  isChecked={settings.MaintainBalance}
                  onToggle={handleToggleChange}
                  primaryColor={primaryColor}
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="MinFunds">Maintain a balance of:</label>
                <input
                  type="number"
                  id="MinFunds"
                  name="MinFunds"
                  value={settings.MinFunds}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            </div>
          )}
          <button
            type="submit"
            className="themed-button"
            disabled={loading}
          >
            {loading ? 'Updating...' : 'Update Settings'}
          </button>
        </form>
      </div>
    </>
  );
};

export default SettingsPage;



// import React, { useState, useEffect } from 'react';
// import PageHeader from './PageHeader';
// import ToggleSwitch from './ToggleSwitch';
// import MobileHeader from './MobileHeader';
// import { useTheme } from './ThemeContext';
// import Spinner from './Spinner';
// import { useAuth } from './AuthContext';

// const SettingsPage = () => {
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const { currentUser } = useAuth();
//   const userRoles = currentUser?.Roles || [];
//   const [settings, setSettings] = useState({
//     CompanyName: '',
//     Email: '',
//     Phone: '',
//     MaintainBalance: false,
//     MinFunds: 0,
//   });
//   const [loading, setLoading] = useState(false);
//   const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
//   const { theme } = useTheme();
//   const primaryColor = theme.primary;

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(`${apiUrl}/dataServer`, {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({
//             action: 'getUserSettings',
//             payload: { userId: currentUser?.userId },
//           }),
//         });

//         if (response.ok) {
//           const fetchedData = await response.json();
//           setSettings(fetchedData);
//         } else {
//           setAlertMessage({ message: `Server Error: ${await response.text()}`, type: 'error' });
//         }
//       } catch (error) {
//         setAlertMessage({ message: `Error: ${error.message}`, type: 'error' });
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (currentUser?.userId) {
//       fetchData();
//     }
//   }, [currentUser?.userId]);

//   const handleToggleChange = () => {
//     setSettings(prevSettings => ({
//       ...prevSettings,
//       MaintainBalance: !prevSettings.MaintainBalance,
//     }));
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setSettings(prevSettings => ({
//       ...prevSettings,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const response = await fetch(`${apiUrl}/dataServer`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           action: 'updateUserSettings',
//           payload: { userId: currentUser?.userId, ...settings },
//         }),
//       });

//       if (response.ok) {
//         setAlertMessage({ message: 'Settings updated successfully.', type: 'success' });
//       } else {
//         setAlertMessage({ message: `Server Error: ${await response.text()}`, type: 'error' });
//       }
//     } catch (error) {
//       setAlertMessage({ message: `Error: ${error.message}`, type: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const closeAlert = () => {
//     setAlertMessage({ message: '', type: '' });
//   };

//   return (
//     <>
//       {loading && <Spinner />}
//       <MobileHeader theme={theme} />
//       <div className="mobile-container">
//         <PageHeader heading="Settings" context="View and edit site settings." />
//         {alertMessage.message && (
//           <div className={`mb-4 p-4 rounded ${alertMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
//             <div className="flex justify-between items-center">
//               <span>{alertMessage.message}</span>
//               <button onClick={closeAlert} className="text-lg font-bold">&times;</button>
//             </div>
//           </div>
//         )}
//         <form onSubmit={handleSubmit}>
//           <div className="mb-10">
//             <h2 className="text-2xl font-semibold mb-4">User Settings</h2>
//             <div className="mb-6">
//               <label className="block text-gray-700 font-medium mb-2" htmlFor="CompanyName">Company Name</label>
//               <input
//                 type="text"
//                 id="CompanyName"
//                 name="CompanyName"
//                 value={settings.CompanyName}
//                 onChange={handleInputChange}
//                 className="w-full p-2 border border-gray-300 rounded"
//               />
//             </div>
//             <div className="mb-6">
//               <label className="block text-gray-700 font-medium mb-2" htmlFor="Email">Email</label>
//               <input
//                 type="email"
//                 id="Email"
//                 name="Email"
//                 value={settings.Email}
//                 onChange={handleInputChange}
//                 className="w-full p-2 border border-gray-300 rounded"
//               />
//               <p className="text-sm text-gray-500 mt-1">Updating this value will not update your login information.</p>
//             </div>
//             <div className="mb-6">
//               <label className="block text-gray-700 font-medium mb-2" htmlFor="Phone">Phone</label>
//               <input
//                 type="tel"
//                 id="Phone"
//                 name="Phone"
//                 value={settings.Phone}
//                 onChange={handleInputChange}
//                 className="w-full p-2 border border-gray-300 rounded"
//               />
//             </div>
//           </div>
//           <div className="mb-10">
//             <h2 className="text-2xl font-semibold mb-4">Financial Settings</h2>
//             <div className="mb-6">
//               <label className="block text-gray-700 font-medium mb-2">Auto Reload</label>
//               <ToggleSwitch
//                 isChecked={settings.MaintainBalance}
//                 onToggle={handleToggleChange}
//                 primaryColor={primaryColor}
//               />
//             </div>
//             <div className="mb-6">
//               <label className="block text-gray-700 font-medium mb-2" htmlFor="MinFunds">Maintain a balance of:</label>
//               <input
//                 type="number"
//                 id="MinFunds"
//                 name="MinFunds"
//                 value={settings.MinFunds}
//                 onChange={handleInputChange}
//                 className="w-full p-2 border border-gray-300 rounded"
//               />
//             </div>
//           </div>
//           <button
//             type="submit"
//             className="themed-button"
//             disabled={loading}
//           >
//             {loading ? 'Updating...' : 'Update Settings'}
//           </button>
//         </form>
//       </div>
//     </>
//   );
// };

// export default SettingsPage;
