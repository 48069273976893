import React from 'react';

const InvoiceModal = ({ invoice, onClose }) => {
  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([document.getElementById('invoice-content').innerHTML], { type: 'application/pdf' });
    element.href = URL.createObjectURL(file);
    element.download = `${invoice.invoiceNumber}.pdf`;
    document.body.appendChild(element);
    element.click();
  };

  const calculateTotalWithTaxes = () => {
    let total = invoice.totalAmount || 0;
    if (invoice.includeTaxes) {
      total *= 1.1; // Assuming a 10% tax rate
    }
    if (invoice.deliveryFee) {
      total += invoice.deliveryFee;
    }
    return total.toFixed(2);
  };

  // Default data for missing fields
  const customerInfo = invoice.customerInfo || { name: 'Unknown Customer', address: 'Unknown Address' };
  const deliveryAddress = invoice.deliveryAddress || 'Unknown Delivery Address';

  return (
    <div id="invoice-content" className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Invoice</h2>
        <div className="flex space-x-4">
          <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded">
            Print
          </button>
          <button onClick={handleDownload} className="bg-green-500 text-white px-4 py-2 rounded">
            Download
          </button>
          <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded">
            Close
          </button>
        </div>
      </div>
      <div className="border p-4 rounded-lg">
        <div className="flex justify-between mb-4">
          <div>
            <img src="/logos/logo-pelican.webp" alt="Company Logo" className="h-12 mb-2" />
            <h3 className="font-bold">Pelican Fueling Inc</h3>
            <p>The Fuel You Need, When You Need It</p>
            <p>www.pelicanfueling.com</p>
          </div>
          <div className="text-right">
            <p>Customer #: {invoice.customerNumber || 'N/A'}</p>
            <p>Payment Terms: {invoice.paymentTerms || 'N/A'}</p>
            <p>Invoice #: {invoice.invoiceNumber || 'N/A'}</p>
            <p>Invoice Date: {invoice.invoiceDate || 'N/A'}</p>
            <p>Due Date: {invoice.dueDate || 'N/A'}</p>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="font-bold mb-2">Bill To:</h3>
          <p>{customerInfo.name}</p>
          <p>{customerInfo.address}</p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold mb-2">Amount Enclosed:</h3>
          <p>${invoice.totalAmount ? invoice.totalAmount.toFixed(2) : '0.00'}</p>
        </div>
        <table className="table-auto w-full mb-4">
          <thead>
            <tr>
              <th className="px-4 py-2">Quantity</th>
              <th className="px-4 py-2">Item Number</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Unit Price</th>
              <th className="px-4 py-2">Total</th>
            </tr>
          </thead>
          <tbody>
            {invoice.lineItems && invoice.lineItems.length > 0 ? (
              invoice.lineItems.map((item, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{item.quantity || 'N/A'}</td>
                  <td className="border px-4 py-2">{item.itemNumber || 'N/A'}</td>
                  <td className="border px-4 py-2">{item.description || 'N/A'}</td>
                  <td className="border px-4 py-2">${item.unitPrice ? item.unitPrice.toFixed(2) : '0.00'}</td>
                  <td className="border px-4 py-2">${item.total ? item.total.toFixed(2) : '0.00'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border px-4 py-2" colSpan="5">No line items available</td>
              </tr>
            )}
            {invoice.deliveryFee > 0 && (
              <tr>
                <td className="border px-4 py-2"></td>
                <td className="border px-4 py-2"></td>
                <td className="border px-4 py-2">Delivery Fee</td>
                <td className="border px-4 py-2"></td>
                <td className="border px-4 py-2">${invoice.deliveryFee.toFixed(2)}</td>
              </tr>
            )}
            {invoice.includeTaxes && (
              <tr>
                <td className="border px-4 py-2"></td>
                <td className="border px-4 py-2"></td>
                <td className="border px-4 py-2">Taxes (10%)</td>
                <td className="border px-4 py-2"></td>
                <td className="border px-4 py-2">${(invoice.totalAmount * 0.1).toFixed(2)}</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="mb-4">
          <h3 className="font-bold mb-2">Delivery/Service Address:</h3>
          <p>{deliveryAddress}</p>
        </div>
        <div className="border-t pt-4">
          <h3 className="font-bold mb-2">Total Due:</h3>
          <p>${calculateTotalWithTaxes()}</p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceModal;




// import React from 'react';

// const InvoiceModal = ({ onClose }) => {
//   const handlePrint = () => {
//     window.print();
//   };

//   const handleDownload = () => {
//     const element = document.createElement('a');
//     const file = new Blob([document.getElementById('invoice-content').innerHTML], { type: 'application/pdf' });
//     element.href = URL.createObjectURL(file);
//     element.download = 'invoice.pdf';
//     document.body.appendChild(element);
//     element.click();
//   };

//   return (
//     <div id="invoice-content" className="p-4">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-xl font-bold">Invoice</h2>
//         <div className="flex space-x-4">
//           <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded">
//             Print
//           </button>
//           <button onClick={handleDownload} className="bg-green-500 text-white px-4 py-2 rounded">
//             Download
//           </button>
//           <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded">
//             Close
//           </button>
//         </div>
//       </div>
//       <div className="border p-4 rounded-lg">
//         <div className="flex justify-between mb-4">
//           <div>
//             <img src="/logos/logo-pelican.webp" alt="Company Logo" className="h-12 mb-2" />
//             <h3 className="font-bold">Pelican Fueling Inc</h3>
//             <p>The Fuel You Need, When You Need It</p>
//             <p>www.pelicanfueling.com</p>
//           </div>
//           <div className="text-right">
//             <p>Customer #: 284606</p>
//             <p>Payment Terms: EFT 10th & 25th 2%</p>
//             <p>Invoice #: 10611240</p>
//             <p>Invoice Date: 07/31/24</p>
//             <p>Due Date: 08/15/24</p>
//           </div>
//         </div>
//         <div className="mb-4">
//           <h3 className="font-bold mb-2">Bill To:</h3>
//           <p>Billy B Forkey</p>
//           <p>16453 Hollow Rd</p>
//           <p>Caldwell, ID 83607</p>
//         </div>
//         <div className="mb-4">
//           <h3 className="font-bold mb-2">Amount Enclosed:</h3>
//           <p>$449.69</p>
//           <p className="text-sm">*Total if paid in full by 08/16/2024: $0.00</p>
//         </div>
//         <table className="table-auto w-full mb-4">
//           <thead>
//             <tr>
//               <th className="px-4 py-2">Quantity</th>
//               <th className="px-4 py-2">Item Number</th>
//               <th className="px-4 py-2">Description</th>
//               <th className="px-4 py-2">Unit Price</th>
//               <th className="px-4 py-2">Total</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td className="border px-4 py-2">231.80</td>
//               <td className="border px-4 py-2">PROPANE LP</td>
//               <td className="border px-4 py-2">Propane</td>
//               <td className="border px-4 py-2">$1.9400</td>
//               <td className="border px-4 py-2">$449.69</td>
//             </tr>
//           </tbody>
//         </table>
//         <div className="mb-4">
//           <h3 className="font-bold mb-2">Delivery/Service Address:</h3>
//           <p>16453 Hollow Rd, Caldwell, ID 83607</p>
//         </div>
//         <div className="mb-4">
//           <h3 className="font-bold mb-2">Receipt Notes:</h3>
//           <p>Delivered on 07/31/2024 at 9:54 AM</p>
//         </div>
//         <div className="border-t pt-4">
//           <h3 className="font-bold mb-2">Total Due:</h3>
//           <p>$449.69</p>
//           <p className="text-sm">*Prompt Pay Discount not available if paid by credit/debit card</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InvoiceModal;
