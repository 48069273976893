import React, { useState } from 'react';
import InvoiceModal from './InvoiceModal';
import ServiceModal from './ServiceModal';

const sampleOrders = [
  {
    id: 1,
    customer: 'Acme Corp',
    accountNumber: 'AC12345',
    lineItems: [
      { product: 'Cleanboost CB Gold', quantity: 500, unitPrice: 2.5 },
      { product: 'Diesel #2', quantity: 2000, unitPrice: 2.0 },
    ],
    assignedDriver: 'Driver 1',
    status: 'Open',
    orderDate: '2024-08-01',
    deliverByDate: '2024-08-10',
    specialRequests: 'Deliver during business hours only.',
  },
  {
    id: 2,
    customer: 'Beta Industries',
    accountNumber: 'BI67890',
    lineItems: [
      { product: 'Syngear FG Series', quantity: 300, unitPrice: 4.0 },
    ],
    assignedDriver: 'Driver 1',
    status: 'Open',
    orderDate: '2024-08-02',
    deliverByDate: '2024-08-12',
    specialRequests: 'Fill the tank completely.',
  },
  // Add more sample orders as needed
];

const Dashboard2 = () => {
  const [viewMode, setViewMode] = useState('User');
  const [showInvoicesModal, setShowInvoicesModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false); // For the Payment Modal
  const [showPDFViewerModal, setShowPDFViewerModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState({ open: false, type: '' });
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orders, setOrders] = useState(sampleOrders);

  const handleViewAllInvoicesClick = () => {
    setShowInvoicesModal(true);
  };

  const handleCloseInvoicesModal = () => {
    setShowInvoicesModal(false);
  };

  const handleViewDetailsClick = (invoice) => {
    setSelectedInvoice(invoice);
    setShowPDFViewerModal(true);
  };

  const handleClosePDFViewerModal = () => {
    setShowPDFViewerModal(false);
  };

  const handleServiceClick = (type) => {
    setShowServiceModal({ open: true, type });
  };

  const handleCloseServiceModal = () => {
    setShowServiceModal({ open: false, type: '' });
  };

  const handleCompleteOrderClick = (order) => {
    setSelectedOrder(order);
  };

  const handleCompleteOrderSubmit = () => {
    // Logic to update the order status and close the modal
    setOrders(orders.map(o => o.id === selectedOrder.id ? { ...o, status: 'Completed' } : o));
    setSelectedOrder(null);
  };

  const handleMakePaymentClick = () => {
    setShowPaymentModal(true);
  };

  const handleClosePaymentModal = () => {
    setShowPaymentModal(false);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div>
          <select
            className="border rounded py-2 px-4"
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
          >
            <option value="User">User View</option>
            <option value="Driver">Driver View</option>
          </select>
        </div>
      </div>

      {viewMode === 'User' ? (
        <>
          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <div className="mb-4">
              <h2 className="text-xl font-bold">Account Details</h2>
              <p>BILLY B FORKEY</p>
              <p>16453 HOLLOW RD</p>
              <p>CALDWELL, ID 83607</p>
              <p>billyforkey@gmail.com</p>
              <p>Status: Active</p>
            </div>
            <div className="mb-4">
              <h2 className="text-xl font-bold">Amount Due Details</h2>
              <p>Total Amount Due: <span className="text-red-500">$449.69</span></p>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded mt-2"
                onClick={handleMakePaymentClick} // Trigger the payment modal
              >
                Make a Payment
              </button>
              <p>or <a href="#" className="text-blue-500">Setup Automatic Payments</a></p>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold">Open Invoices</h2>
            <table className="table-auto w-full mb-4">
              <thead>
                <tr>
                  <th className="px-4 py-2">Created On</th>
                  <th className="px-4 py-2">Invoice Number</th>
                  <th className="px-4 py-2">Type</th>
                  <th className="px-4 py-2">Amount</th>
                  <th className="px-4 py-2">Amount Remaining</th>
                  <th className="px-4 py-2">Due On</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2">7/31/2024</td>
                  <td className="border px-4 py-2">10611240</td>
                  <td className="border px-4 py-2">Delivery</td>
                  <td className="border px-4 py-2">$449.69</td>
                  <td className="border px-4 py-2">$449.69</td>
                  <td className="border px-4 py-2">8/15/2024</td>
                  <td className="border px-4 py-2">
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded"
                      onClick={() => handleViewDetailsClick({ /* Pass the invoice object here */ })}
                    >
                      Details
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleViewAllInvoicesClick}
            >
              View All Invoices
            </button>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold">My Services</h2>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                onChange={(e) => handleServiceClick(e.target.value)}
              >
                <option value="" disabled selected>Select a service...</option>
                <option value="requestService">Request Service</option>
                <option value="tanks">Tanks</option>
                <option value="meters">Meters</option>
                <option value="equipment">Equipment</option>
              </select>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold">Recent Activity</h2>
            <p>Last Delivery: 7/31/2024</p>
            <p>Last Payment: 2/26/2024 - $733.34</p>
          </div>
        </>
      ) : (
        <>
          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold">Open Orders</h2>
            <table className="table-auto w-full mb-4">
              <thead>
                <tr>
                  <th className="px-4 py-2">Order Date</th>
                  <th className="px-4 py-2">Customer</th>
                  <th className="px-4 py-2">Deliver By</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.filter(order => order.status === 'Open').map(order => (
                  <tr key={order.id}>
                    <td className="border px-4 py-2">{order.orderDate}</td>
                    <td className="border px-4 py-2">{order.customer}</td>
                    <td className="border px-4 py-2">{order.deliverByDate}</td>
                    <td className="border px-4 py-2">{order.status}</td>
                    <td className="border px-4 py-2">
                      <button
                        className="bg-green-500 text-white px-2 py-1 rounded"
                        onClick={() => handleCompleteOrderClick(order)}
                      >
                        Complete Order
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {selectedOrder && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
              <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-2/3 max-h-screen overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">Complete Order</h2>
                <div className="mb-4">
                  <h3 className="text-lg font-bold mb-2">Order Details</h3>
                  <p><strong>Customer:</strong> {selectedOrder.customer}</p>
                  <p><strong>Order Date:</strong> {selectedOrder.orderDate}</p>
                  <p><strong>Deliver By:</strong> {selectedOrder.deliverByDate}</p>
                  <table className="w-full border border-gray-300 mb-6">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border border-gray-300 p-2">Product</th>
                        <th className="border border-gray-300 p-2">Quantity Ordered</th>
                        <th className="border border-gray-300 p-2">Quantity Delivered</th>
                        <th className="border border-gray-300 p-2">Unit Price</th>
                        <th className="border border-gray-300 p-2">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOrder.lineItems.map((item, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-2">{item.product}</td>
                          <td className="border border-gray-300 p-2">{item.quantity}</td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="number"
                              className="border rounded w-full py-1 px-2"
                              value={item.quantity}
                              onChange={(e) => {
                                const updatedLineItems = [...selectedOrder.lineItems];
                                updatedLineItems[index].quantity = parseFloat(e.target.value);
                                setSelectedOrder({ ...selectedOrder, lineItems: updatedLineItems });
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">${item.unitPrice.toFixed(2)}</td>
                          <td className="border border-gray-300 p-2">${(item.unitPrice * item.quantity).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end">
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                    onClick={() => setSelectedOrder(null)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded"
                    onClick={handleCompleteOrderSubmit}
                  >
                    Submit Completion
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {showInvoicesModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-2/3">
            {/* Example of Invoice Modal */}
            <div className="mb-4">
              <h2 className="text-xl font-bold">Account History</h2>
              <div className="mt-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">Search</label>
                  <div className="flex">
                    <input
                      type="date"
                      className="border rounded-l py-2 px-3"
                      defaultValue="2024-07-08"
                    />
                    <input
                      type="date"
                      className="border rounded-r py-2 px-3"
                      defaultValue="2024-08-07"
                    />
                    <button className="bg-blue-500 text-white px-4 py-2 rounded ml-2">
                      Search
                    </button>
                  </div>
                </div>
                <table className="table-auto w-full mb-4">
                  <thead>
                    <tr>
                      <th className="px-4 py-2">Transaction Date</th>
                      <th className="px-4 py-2">Transaction</th>
                      <th className="px-4 py-2">Type</th>
                      <th className="px-4 py-2">Sub Type</th>
                      <th className="px-4 py-2">Due Date</th>
                      <th className="px-4 py-2">Amount</th>
                      <th className="px-4 py-2">Amount Remaining</th>
                      <th className="px-4 py-2">Status</th>
                      <th className="px-4 py-2">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2">7/31/2024</td>
                      <td className="border px-4 py-2">10611240</td>
                      <td className="border px-4 py-2">Delivery</td>
                      <td className="border px-4 py-2"></td>
                      <td className="border px-4 py-2">8/15/2024</td>
                      <td className="border px-4 py-2">$449.69</td>
                      <td className="border px-4 py-2">$449.69</td>
                      <td className="border px-4 py-2"><span className="bg-yellow-500 text-white px-2 py-1 rounded">Unpaid</span></td>
                      <td className="border px-4 py-2">
                        <button className="bg-blue-500 text-white px-2 py-1 rounded">Details</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded mt-4"
                  onClick={handleCloseInvoicesModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPDFViewerModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-40">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-2/3 max-h-screen overflow-y-auto">
            <InvoiceModal invoice={selectedInvoice} onClose={handleClosePDFViewerModal} />
            <button
              className="bg-red-500 text-white px-4 py-2 rounded mt-4"
              onClick={handleClosePDFViewerModal}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showPaymentModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-40">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/3 max-h-screen overflow-y-auto">
            <div className="mb-4">
              <h2 className="text-xl font-bold">Make a One-Time Payment</h2>
              <div className="mt-4">
                <div className="flex items-center">
                  <input type="radio" name="paymentOption" className="mr-2" defaultChecked />
                  <label className="font-bold">Pay Current Amount Due</label>
                </div>
                <p className="ml-6">$449.69</p>
              </div>
              <div className="mt-4">
                <div className="flex items-center">
                  <input type="radio" name="paymentOption" className="mr-2" />
                  <label className="font-bold">Pay Specific Amount</label>
                </div>
                <input
                  type="text"
                  placeholder="$"
                  className="border rounded w-full py-2 px-3 mt-2"
                />
              </div>
              <div className="mt-4">
                <label className="font-bold">Notes</label>
                <textarea className="border rounded w-full py-2 px-3 mt-2"></textarea>
              </div>
              <div className="mt-4">
                <label className="font-bold">Payment</label>
                <select className="border rounded w-full py-2 px-3 mt-2">
                  <option>Select a Payment Method ...</option>
                </select>
                <button className="bg-blue-500 text-white px-4 py-2 rounded mt-2">
                  Update Payment Methods
                </button>
              </div>
              <div className="mt-4">
                <p>Payment Amount: $449.69</p>
                <p>Convenience Fee: $0.00</p>
                <p>SubTotal: $449.69</p>
              </div>
              <div className="mt-4">
                <input type="checkbox" className="mr-2" />
                <label>I agree to pay for the amount as listed above.</label>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleClosePaymentModal}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleClosePaymentModal}
              >
                Make a Payment
              </button>
            </div>
          </div>
        </div>
      )}

      {showServiceModal.open && (
        <ServiceModal
          type={showServiceModal.type}
          onClose={handleCloseServiceModal}
        />
      )}
    </div>
  );
};

export default Dashboard2;
