import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SubTableV2 from './SubTableV2';
import MobileHeader from './MobileHeader';
import { useTheme } from './ThemeContext';
import Spinner from './Spinner';
import { useDispatch, useSelector } from 'react-redux';
import CardGroupsAddCardModal from './CardGroupsAddCardModal';
import AddUserModal from './AddUserModal';
import PageHeader from './PageHeader';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { updateCardGroupData, selectCardGroupData, addCardsToGroup, setCardGroupData, removeCardFromGroup, addUserTenantMappings, removeUserTenantMapping } from './cardGroupSlice';

const EditCardGroup = () => {
    const { currentUser } = useAuth();
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cardGroup = useSelector(selectCardGroupData);
    const [cardDataOriginal, setCardDataOriginal] = useState(cardGroup.cardSummaries || []);
    const [userTenantMappings, setUserTenantMappings] = useState(cardGroup.userTenantMappings || []);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAddCardModal, setShowAddCardModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [cardGroupNumber, setCardGroupNumber] = useState(cardGroup && cardGroup.cardGroup ? cardGroup.cardGroup.cardGroupID : '');
    const [policyNumber, setPolicyNumber] = useState(cardGroup && cardGroup.cardGroup ? String(cardGroup.cardGroup.PolicyNumber) : '');
    const [subfleetId, setSubfleetId] = useState(cardGroup?.cardGroup?.SubfleetId || '');
    const [successMessage, setSuccessMessage] = useState('');
    const [cardTableAlert, setCardTableAlert] = useState('');
    const [userTableAlert, setUserTableAlert] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [removalType, setRemovalType] = useState(null); // 'card' or 'user'
    const [removalIdentifier, setRemovalIdentifier] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const policies = useSelector(state => state.policies.activePolicies);
    const apiUrl = process.env.REACT_APP_API_URL;

    const clearSuccessMessage = () => setSuccessMessage('');
    const toggleCardModal = () => setShowAddCardModal(!showAddCardModal);
    const toggleUserModal = () => setShowAddUserModal(!showAddUserModal);

    const clearAlert = () => setAlertMessage('');
    const clearCardAlert = () => setCardTableAlert('');
    const clearUserAlert = () => setUserTableAlert('');

    useEffect(() => {
        const storedCardGroupData = localStorage.getItem('cardGroupData');
        // console.log('Stored Data:', storedCardGroupData);
        const parsedData = storedCardGroupData ? JSON.parse(storedCardGroupData) : null;

        if (parsedData && !cardGroup.cardGroup) {
            // console.log("Rehydrating card group data from local storage");
            dispatch(setCardGroupData(parsedData));
            setCardDataOriginal(parsedData.cardSummaries);
            if (parsedData?.cardGroup) {
                setCardGroupNumber(parsedData.cardGroup.cardGroupID || '');
                setPolicyNumber(parsedData.cardGroup.PolicyNumber || '');
                setUserTenantMappings(parsedData.userTenantMappings || '');
            }
        }
    }, [dispatch, cardGroup.cardGroup]);

    useEffect(() => {
        if (cardGroup) {
            localStorage.setItem('cardGroupData', JSON.stringify(cardGroup));
            setCardDataOriginal(cardGroup.cardSummaries);
            if (cardGroup?.cardGroup) {
                setCardGroupNumber(cardGroup.cardGroup.cardGroupID || '');
                setPolicyNumber(cardGroup.cardGroup.PolicyNumber || '');
            }
        }
    }, [cardGroup]);



    const onCardsAdded = (newCards) => {
        const existingCardNumbers = new Set(cardDataOriginal.map(card => card.CardNumber));
        const filteredNewCards = newCards.filter(card => !existingCardNumbers.has(card.CardNumber));

        if (filteredNewCards.length > 0) {
            setCardDataOriginal([...cardDataOriginal, ...filteredNewCards]);
            dispatch(addCardsToGroup(filteredNewCards));
            setSuccessMessage('Card(s) successfully added to the group.');
            // console.log("Updated cardData: ", [...cardDataOriginal, ...filteredNewCards]);
        } else {
            setSuccessMessage('No new cards added. Duplicate selections were not added.');
        }
    };

    const handleRemove = (type, identifier) => {
        setRemovalType(type);
        setRemovalIdentifier(identifier);
        const message = type === 'card'
            ? 'Are you sure you want to remove this card from the card group?'
            : 'Are you sure you want to remove this user from the card group?';
        setConfirmationMessage(message);
        setShowConfirmationModal(true);
    };

    const confirmRemoval = async () => {
        if (!removalIdentifier) return;

        setLoading(true);
        const payload = removalType === 'card' ? { CardNumber: removalIdentifier, NetworkId: cardGroup.cardGroup.NetworkId } : { UserId: removalIdentifier, NetworkId: cardGroup.cardGroup.NetworkId };
        // console.log('Payload: ', payload);

        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action: 'removeFromCardGroup', payload })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            await response.json();

            if (removalType === 'card') {
                const updatedCardData = cardDataOriginal.filter(card => card.CardNumber !== removalIdentifier);
                setCardDataOriginal(updatedCardData);
                dispatch(removeCardFromGroup(removalIdentifier));
                setCardTableAlert(`Successfully removed the ${removalType} from the card group.`);
            } else {
                const updatedUserMappings = userTenantMappings.filter(user => user.UserId !== removalIdentifier);
                setUserTenantMappings(updatedUserMappings);
                dispatch(removeUserTenantMapping(removalIdentifier));
                setUserTableAlert(`Successfully removed the ${removalType} from the card group.`);
            }
        } catch (error) {
            console.error(`Failed to remove the ${removalType}: `, error);
            setCardTableAlert(`Failed to remove the ${removalType}: ${error.message}`);
        } finally {
            setLoading(false);
            setShowConfirmationModal(false); // Close modal after handling
            setRemovalIdentifier(null); // Reset identifier
            setRemovalType(null); // Reset type
        }
    };

    const onUsersAdded = (newUsers) => {
        const existingUserIds = new Set(userTenantMappings.map(user => user.UserId));
        const filteredNewUsers = newUsers.filter(user => !existingUserIds.has(user.UserId));

        if (filteredNewUsers.length > 0) {
            setUserTenantMappings([...userTenantMappings, ...filteredNewUsers]);
            dispatch(addUserTenantMappings(filteredNewUsers));
            setUserTableAlert('Successfully added user(s) to the group.');
        } else {
            setUserTableAlert('No new users were added. Duplicate selections detected and were not added.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateCardGroupData({ [name]: value }));

        if (name === 'PolicyNumber') {
            setPolicyNumber(String(value));
        } else if (name === 'SubfleetId') {
            setSubfleetId(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = {
            cardGroupId: cardGroup.cardGroup.cardGroupID,
            groupName: cardGroup.cardGroup.groupName,
            description: cardGroup.cardGroup.description,
            policyNumber: policyNumber,
            subfleetId: subfleetId,
            userId: currentUser.userId,
            transactionType: 'EDIT',
        };
        // console.log('Payload: ', payload);
        try {
            const response = await fetch(`${apiUrl}/dataServer`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ action: 'addOrEditCardGroup', payload })
            });

            if (!response.ok) {
                throw new Error(`Failed to update card group: ${response.statusText}`);
            }

            const responseData = await response.json();
            setAlertMessage('Successfully updated the Card Group!');
            // console.log('Update response:', responseData);
        } catch (error) {
            setAlertMessage(`Failed to update: ${error.message}`);
            console.error('Error during card group update:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCardEdit = (cardData) => {
        navigate('/editcard', { state: { cardNumber: cardData.CardNumber } });
    };

    const cardColumns = React.useMemo(() => [
        {
            name: 'Card Number',
            selector: row => row.CardNumber,
            sortable: true
        },
        {
            name: 'Driver Name',
            selector: row => row.DriverName,
            sortable: true
        },
        {
            name: 'Driver Id',
            selector: row => row.DriverId,
            sortable: true,
            hide: 'sm'

        },
        {
            name: 'Card Subfleet',
            selector: row => row.CardSubfleet,
            sortable: true,
            hide: 'sm'
        },
        {
            name: 'Policy Subfleet',
            selector: row => row.PolicySubfleet,
            sortable: true,
            hide: 'sm'
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => handleCardEdit(row)}
                        className="text-gray-500 hover:text-gray-700"
                        title="Edit Card"
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                        onClick={() => handleRemove('card', row.CardNumber)}
                        className="text-red-500 hover:text-red-700"
                        title="Remove Card From Group"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                </div>
            )
        },
    ], [handleCardEdit]);

    const handleUserEdit = (row) => {
        // console.log("Edit User:", row);        
    };


    const userTenantColumns = useMemo(() => [
        {
            name: 'First Name',
            selector: row => row.FirstName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Last Name',
            selector: row => row.LastName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Phone',
            selector: row => row.Phone,
            sortable: true,
            wrap: true
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            wrap: true
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => {
                            navigate(`/EditUserForm/${row.RecordId}`, { state: { rowData: row } });
                        }}
                        className="text-gray-500 hover:text-gray-700"
                        title="Edit User"
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                        onClick={() => handleRemove('user', row.UserId)}
                        className="text-red-500 hover:text-red-700"
                        title="Remove User From Group"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                </div>
            )
        }
    ], [handleUserEdit]);

    const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
        if (!isOpen) return null;

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-10">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                    <h3 className="text-lg font-medium text-gray-900">Confirm Removal</h3>
                    <p className="text-gray-500 my-4">{message}</p>
                    <div className="flex justify-end gap-4">
                        <button className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded" onClick={onClose}>No</button>
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={onConfirm}>Yes</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="mobile-container mx-auto px-4 sm:px-6 lg:px-8">
            <MobileHeader theme={theme} />
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={() => confirmRemoval(removalType, removalIdentifier)}
                message={confirmationMessage}
            />

            <PageHeader
                heading="Account Details"
                context="Edit account details."
            />
            {loading && <Spinner />}
            <div className="text-end mb-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-gray-700 text-sm mr-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
          Go Back
        </button>
      </div>
            {alertMessage && (
                <div className={`alert p-4 rounded ${alertMessage.startsWith('Successfully') ? 'bg-green-200 text-green-600' : 'bg-red-200 text-red-600'} flex justify-between items-center`}>
                    {alertMessage}
                    <button onClick={clearAlert} className="text-lg font-bold">×</button>
                </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-2 mb-8">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="groupName" value={cardGroup?.cardGroup?.groupName || ''} onChange={handleInputChange} className="mt-1 p-2 w-full border rounded-md" />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <input type="text" name="description" value={cardGroup?.cardGroup?.description || ''} onChange={handleInputChange} className="mt-1 p-2 w-full border rounded-md" />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Policy Number</label>
                    <select
                        id="PolicyNumber"
                        name="PolicyNumber"
                        value={policyNumber || ''}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                        required
                    >
                        <option value="">Select A Policy</option>
                        {policies.map(policy => (
                            <option key={policy.PolicyNumber} value={String(policy.PolicyNumber)}>
                                {policy.PolicyNumber} - {policy.Description}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Subfleet ID</label>
                    <input
                        type="text"
                        name="SubfleetId"
                        value={subfleetId || ''}
                        onChange={handleInputChange}
                        className="mt-1 p-2 w-full border rounded-md"
                        maxLength="24"
                    />
                </div>
                <button type="submit" disabled={loading} className="themed-button">
                    Update
                </button>
            </form>
            {successMessage && (
                <div className={`alert p-4 rounded bg-green-200 text-green-600 flex justify-between items-center`}>
                    {successMessage}
                    <button onClick={clearSuccessMessage} className="text-lg font-bold">×</button>
                </div>
            )}

            <div className="flex flex-col mb-8">
                <div className="flex justify-end mb-2">
                    <button onClick={toggleCardModal} className="themed-button py-2 px-4 rounded">
                        Add Cards
                    </button>
                </div>
                <SubTableV2
                    columns={cardColumns}
                    title="Cards"
                    data={cardDataOriginal}
                    loading={loading}
                    clearAlert={clearCardAlert}
                    alertMessage={cardTableAlert}

                />
            </div>
            <div className="flex flex-col mb-8">
                <div className="flex justify-end mb-2">
                    <button onClick={toggleUserModal} className="themed-button py-2 px-4 rounded">
                        Add Users
                    </button>
                </div>
                <SubTableV2
                    columns={userTenantColumns}
                    title="Users"
                    data={userTenantMappings}
                    loading={loading}
                    clearAlert={clearUserAlert}
                    alertMessage={userTableAlert}
                />
            </div>
            <CardGroupsAddCardModal
                isOpen={showAddCardModal}
                onClose={() => setShowAddCardModal(false)}
                cardGroupId={cardGroupNumber}
                onCardsAdded={onCardsAdded}
            />
            <AddUserModal
                isOpen={showAddUserModal}
                onClose={() => setShowAddUserModal(false)}
                cardGroupId={cardGroupNumber}
                onUsersAdded={onUsersAdded}
            />
        </div>
    );
};

export default EditCardGroup;
