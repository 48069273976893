import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTachometerAlt,
  faCreditCard,
  faChartBar,
  faCog,
  faMapMarkerAlt,
  faCalculator,
  faGasPump,
  faSignOutAlt,
  faQuestionCircle,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const MobileHeader = ({ theme }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);
  const userRoles = currentUser?.Roles || [];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setIsMenuOpen(false); // Close the menu after navigation
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
    setIsMenuOpen(false); // Close the menu after logout
  };

  return (
    <div className='header-parent'>
      <div className="mobile-header md:hidden">
        {/* Invisible placeholder to balance the header */}
        <div className="header-placeholder"></div>

        {/* Centered logo and company name */}
        <div className="header-center">
          <img
            src={theme.logo}
            alt="Company Logo"
            className="header-logo"
          />
        </div>

        {/* Hamburger menu */}
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleMenu}
          className="text-lg cursor-pointer"
          style={{ marginRight: '15px' }}
        />
      </div>
      {isMenuOpen && (
        <div className="mobile-menu">
          <ul className="text-lg">
          {!userRoles.includes('Driver') && (
            <>
            <li className={`menu-item ${location.pathname === "/dashboard" ? "selected" : ""}`}
                onClick={() => handleNavigate("/dashboard")}>
              <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
              Dashboard
            </li>
            <li className={`menu-item ${location.pathname === "/usercards" ? "selected" : ""}`}
                onClick={() => handleNavigate("/usercards")}>
              <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
              Fuel Cards
            </li>
            <li
              className={`menu-item mb-2 ${location.pathname === "/userusers" ? "selected" : ""
                }`}
              onClick={() => handleNavigate("/userusers")}
              style={{ color: theme.menuText }}
            >
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              Users
            </li>
            <li className={`menu-item ${location.pathname === "/usertransactions" ? "selected" : ""}`}
                onClick={() => handleNavigate("/usertransactions")}>
              <FontAwesomeIcon icon={faGasPump} className="mr-2" />
              Fuel Transactions
            </li>
            <li className={`menu-item ${location.pathname === "/useraccounting" ? "selected" : ""}`}
                onClick={() => handleNavigate("/useraccounting")}>
              <FontAwesomeIcon icon={faCalculator} className="mr-2" />
              Accounting
            </li>
            <li className={`menu-item ${location.pathname === "/userreporting" ? "selected" : ""}`}
                onClick={() => handleNavigate("/userreporting")}>
              <FontAwesomeIcon icon={faChartBar} className="mr-2" />
              Reports
            </li>
            </>
            )}
            <li className={`menu-item ${location.pathname === "/locationfinder" ? "selected" : ""}`}
                onClick={() => handleNavigate("/locationfinder")}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
              Locations
            </li>            
            <li className={`menu-item ${location.pathname === "/faq" ? "selected" : ""}`}
                onClick={() => handleNavigate("/faq")}>
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              FAQ
            </li>
            <li className={`menu-item ${location.pathname === "/settings" ? "selected" : ""}`} 
                onClick={() => handleNavigate("/settings")}>
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              Settings
            </li>
            <li className="menu-item" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Logout
            </li>
            {(currentUser?.isAdmin || currentUser?.isContractAdmin) && (
              <li className={`menu-item ${location.pathname === '/admin-settings' ? 'selected' : ''}`}
                  onClick={() => handleNavigate('/admin-settings')}>
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                Admin Settings
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
