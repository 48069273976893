import React from 'react';

const ServiceModal = ({ type, onClose }) => {
  const renderContent = () => {
    switch (type) {
      case 'requestService':
        return (
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Request a Delivery</h2>
            <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
              <p>If you would like to pre-pay for your delivery, <a href="#" className="underline">click here</a>.</p>
            </div>
            <div className="bg-gray-50 shadow-md rounded-lg p-6 mb-6">
              <h3 className="text-lg font-bold mb-4">How to tell if you need a delivery?</h3>
              <p className="mb-4">
                Most tanks have a gauge that reads 5 – 95. This is the percentage that still remains in your tank.
              </p>
              <ul className="list-disc pl-4 mb-4">
                <li>If your tank reads over 50% - you probably do not need a delivery at this time.</li>
                <li>If your tank reads less than 50% - please fill out the form below and we will see you soon!</li>
                <li>If you are out of fuel or need an immediate delivery - please fill out the form below, then call your local office to follow up the requested delivery (an off route or emergency delivery fee may apply).</li>
              </ul>
            </div>
            <div className="bg-gray-50 shadow-md rounded-lg p-6 mb-6">
              <h3 className="text-lg font-bold mb-4">Selected Tank</h3>
              <table className="w-full border border-black">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="text-left border border-black p-2">ID</th>
                    <th className="text-left border border-black p-2">Capacity</th>
                    <th className="text-left border border-black p-2">Type</th>
                    <th className="text-left border border-black p-2">Address</th>
                    <th className="text-left border border-black p-2">Level</th>
                    <th className="text-left border border-black p-2">Status</th>
                    <th className="text-left border border-black p-2">Next Delivery</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-black p-2">W00165609-500</td>
                    <td className="border border-black p-2">500 gal.</td>
                    <td className="border border-black p-2">PROPANE LP Fuel Tank</td>
                    <td className="border border-black p-2">16453 HOLLOW RD CALDWELL, ID 83607</td>
                    <td className="p-2 flex items-center"><span className="bg-green-500 text-white px-2 py-1 rounded mr-2">85%</span>Approximately 425 gallons in tank</td>
                    <td className="border border-black p-2">Automatic</td>
                    <td className="border border-black p-2">Estimated Next Delivery: 12/10/2024</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bg-gray-50 shadow-md rounded-lg p-6 mb-6">
              <h3 className="text-lg font-bold mb-4">Delivery Details</h3>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Would you like to fill the tank or specify a number of gallons? *</label>
                <div className="mb-2">
                  <input type="radio" name="deliveryOption" className="mr-2" />
                  <label>Please fill my tank</label>
                </div>
                <div>
                  <input type="radio" name="deliveryOption" className="mr-2" />
                  <label>Specify a number of gallons</label>
                  <input type="text" className="border rounded w-full py-2 px-3 mt-2" placeholder="Number of gallons" />
                  <p className="text-gray-500 text-sm mt-2">For the tank selected, you may only order between 100 and 425 gallons.</p>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">How much product is currently in your tank? *</label>
                <div className="flex">
                  <input type="text" className="border rounded-l w-full py-2 px-3" />
                  <select className="bg-gray-200 border rounded-r px-4 py-2">
                    <option value="%">%</option>
                    <option value="inches">Inches</option>
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Special Requests or Additional Instructions</label>
                <textarea className="border rounded w-full py-2 px-3" placeholder="Enter any special requests or additional instructions"></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Are you out, or almost out, of product? *</label>
                <div className="mb-2">
                  <input type="radio" name="productOut" className="mr-2" />
                  <label>Yes</label>
                </div>
                <div>
                  <input type="radio" name="productOut" className="mr-2" />
                  <label>No</label>
                </div>
              </div>
              <div className="mb-4">
                <input type="checkbox" className="mr-2" />
                <label>I agree to pay all costs and applicable fees necessary to complete my requested delivery.</label>
              </div>
              <button className="bg-blue-500 text-white px-4 py-2 rounded">Submit Order</button>
            </div>
          </div>
        );
      case 'tanks':
        return (
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Tanks</h2>
            <div className="bg-gray-50 shadow-md rounded-lg p-6 mb-6">
              <table className="w-full border border-black">
                <thead>
                  <tr className="bg-gray-200">                    
                    <th className="text-left border border-black p-2">ID</th>
                    <th className="text-left border border-black p-2">Capacity</th>
                    <th className="text-left border border-black p-2">Type</th>
                    <th className="text-left border border-black p-2">Address</th>
                    <th className="text-left border border-black p-2">Level</th>
                    <th className="text-left border border-black p-2">Status</th>
                    <th className="text-left border border-black p-2">Next Delivery</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>                   
                    <td className="border border-black p-2">W00165609-500</td>
                    <td className="border border-black p-2">500 gal.</td>
                    <td className="border border-black p-2">PROPANE LP Fuel Tank</td>
                    <td className="border border-black p-2">16453 HOLLOW RD CALDWELL, ID 83607</td>
                    <td className="p-2 flex items-center"><span className="bg-green-500 text-white px-2 py-1 rounded mr-2">85%</span>Approximately 425 gallons in tank</td>
                    <td className="border border-black p-2">Automatic</td>
                    <td className="border border-black p-2">Estimated Next Delivery: 12/10/2024</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      case 'meters':
        return (
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Meters</h2>
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <p className="text-yellow-600">⚠️ You do not have any meters on your account</p>
            </div>
          </div>
        );
      case 'equipment':
        return (
          <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Equipment</h2>
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <p className="text-yellow-600">⚠️ You do not have any service equipment on your account</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 top-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-start justify-center">
      <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-2/3 mt-10 mb-10">
        {renderContent()}
        <button
          className="bg-red-500 text-white px-4 py-2 rounded mt-4"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ServiceModal;
