import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "./AuthContext";
import { useTheme } from "./ThemeContext";
import Spinner from "./Spinner";
import PageHeader from "./PageHeader";
import FinancialTransactionModal from "./FinancialTransactionModal";
import MainTableV2 from "./MainTableV2";
import MobileHeader from "./MobileHeader";

const FinancialTransactionsAdmin = () => {
  const { theme } = useTheme();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [distinctPolicyNumbers, setDistinctPolicyNumbers] = useState([]);
  const [distinctStatuses, setDistinctStatuses] = useState([]);
  const [distinctTypes, setDistinctTypes] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [modalType, setModalType] = useState(""); // 'new' for new transaction, 'edit' for editing an existing transaction
  const [refreshData, setRefreshData] = useState(0);
  const apiUrl= process.env.REACT_APP_API_URL;
  const filterConfig = [
    { key: 'PolicyNumber', type: 'number' },
    { key: 'Status', type: 'string' },
    { key: 'Type', type: 'string' },
    { key: 'Date', type: 'date'},
    { key: 'Amount', type: 'number' },
    { key: 'Fees', type: 'number' },
    { key: 'TotalAmount', type: 'number' }
  ];


  const { currentUser } = useAuth();
  const primaryColor = theme.primary;
  const clearAlert = () => setAlertMessage(null);
  const openModal = (transaction) => {
    setModalType("edit");
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };
  const handleFormSubmit = async (formData) => {
    setLoading(true);
    try {
        const payload = {
            userId: currentUser.userId,
            modalType,
            formData,
        };

        const response = await fetch(
            `${apiUrl}/dataServer`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    action: 'setFinancialTransAdmin',
                    payload: payload
                }),
            }
        );

        const result = await response.json();
        if (response.ok) {
            setAlertMessage(result.message || "Successfully saved transaction!");
            setRefreshData((prev) => prev + 1);
        } else {
            setAlertMessage(result.message || `Error: ${response.statusText}`);
        }
    } catch (error) {
        setAlertMessage(`Network Error: ${error.message}`);
    } finally {
        setLoading(false);
    }
};


  const handleAddTransaction = () => {
    setModalType("new");
    setIsModalOpen(true);
  };

  const financialTransactionColumns = useMemo(
    () => [
      {
        name: "Transaction ID",
        selector: row => row.TransactionId,
        reorder: true,
      },
      {
        name: "Policy #",
        selector: row => row.PolicyNumber,
        reorder: true,
      },
      {
        name: "Date",
        selector: row => {
          const date = new Date(row.Date);
          const formattedDate = date.toLocaleDateString("en-US");
          const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit", 
            hour12: true,
          });
          return `${formattedDate} ${formattedTime}`;
        },
        reorder: true,
      },
      {
        name: "Type",
        selector: row => row.Type,
        reorder: true,
      },
      {
        name: "Status",
        selector: row => row.Status,
        cell: row => (
          <span
            className={`px-3 py-1 rounded-full text-sm font-semibold ${
              row.Status === "Completed"
                ? "bg-green-200 text-green-800"
                : row.Status === "Pending"
                ? "bg-yellow-200 text-yellow-800"
                : row.Status === "Failed"
                ? "bg-red-200 text-red-800"
                : "bg-gray-200 text-gray-800"
            }`}
          >
            {row.Status}
          </span>
        ),
        reorder: true,
        style: {
          width: '200px'
        }
      },
      {
        name: "Amount",
        selector: row => `$${parseFloat(row.Amount).toFixed(2)}`,
        reorder: true,
      },
      {
        name: "Fees",
        selector: row => `$${parseFloat(row.Fees).toFixed(2)}`,
        reorder: true,
      },
      {
        name: "Total Amount",
        selector: row => `$${parseFloat(row.TotalAmount).toFixed(2)}`,
        reorder: true,
      },
      {
        name: "Actions",
        cell: row => (
          <button
            className="themed-button"
            onClick={() => openModal(row)}
          >
            Details
          </button>
        ),
        reorder: true,
      },
    ],
    []
  );
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/dataServer`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ action: 'fetchAdminFinancialTrans', payload: { userId: currentUser.userId } }),
          }
        );
  
        if (response.ok) {
          const fetchedData = await response.json();
          const filteredData = applyFiltersToData(fetchedData.financialTransactions); // Ensure to access the correct property
          setData(filteredData);
          setOriginalData(fetchedData.financialTransactions); // Ensure to access the correct property
        } else {
          setAlertMessage(`Server Error: ${await response.text()}`);
        }
      } catch (error) {
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [currentUser.userId, refreshData, startDate, endDate, policyNumber, status, type]); // Add filter dependencies

  useEffect(() => {
    if (originalData.length > 0) {
      const policyNumbers = [...new Set(originalData.map((item) => item.PolicyNumber))];
      const statuses = [...new Set(originalData.map((item) => item.Status))];
      const types = [...new Set(originalData.map((item) => item.Type))];
      setDistinctPolicyNumbers(policyNumbers);
      setDistinctStatuses(statuses);
      setDistinctTypes(types);
    }
  }, [originalData]);

  const handleNewTransaction = () => {
    setRefreshData((prev) => prev + 1); 
  };

  // const applyFilters = () => {
  //   const filtered = originalData.filter((item) => {
  //     const date = new Date(item.Date);
  //     const startDateObj = startDate ? new Date(startDate) : null;
  //     const endDateObj = endDate ? new Date(endDate) : null;
  //     const policyNumberInt = parseInt(policyNumber, 10);

  //     return (
  //       (policyNumber ? item.PolicyNumber === policyNumberInt : true) &&
  //       (status ? item.Status === status : true) &&
  //       (type ? item.Type === type : true) &&
  //       (startDate ? date >= startDateObj : true) &&
  //       (endDate ? date <= endDateObj : true)
  //     );
  //   });
  //   setSearchQuery(""); // Clear search query when filters are applied
  //   setData(filtered);
  // };

  const applyFiltersToData = (data) => {
    return data.filter((item) => {
      const date = new Date(item.Date);
      const startDateObj = startDate ? new Date(startDate) : null;
      const endDateObj = endDate ? new Date(endDate) : null;
      const policyNumberInt = parseInt(policyNumber, 10);

      return (
        (policyNumber ? item.PolicyNumber === policyNumberInt : true) &&
        (status ? item.Status === status : true) &&
        (type ? item.Type === type : true) &&
        (startDate ? date >= startDateObj : true) &&
        (endDate ? date <= endDateObj : true)
      );
    });
  };

  const filteredData = useMemo(() => {
    if (searchQuery === "") return data;
    return data.filter((row) => {
      return Object.values(row).some(
        (s) =>
          s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [searchQuery, data]);

  return (
    <>
    <MobileHeader theme={theme} />
    <div className="mobile-container">
      <FinancialTransactionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleFormSubmit}
        initialData={selectedTransaction}
        modalType={modalType}
        onNewTransaction={handleNewTransaction}
      />

      <div>{loading && <Spinner />}</div>
   
      <PageHeader
        heading="Financial Transaction"
        context="View payments, deposits, and card purchases."
      />
      <div className="filters-pane flex flex-wrap justify-between items-center p-4 bg-gray-300 rounded my-3">
  <div className="filter-items flex flex-wrap w-full md:w-auto space-y-2 md:space-y-0 md:space-x-4">
    <label className="flex flex-col w-full md:w-auto">
      <span className="text-sm font-semibold">Start Date:</span>
      <input
        type="date"
        value={startDate || ""}
        onChange={(e) => setStartDate(e.target.value)}
        className="p-1.5 rounded border w-full md:w-auto"
      />
    </label>
    <label className="flex flex-col w-full md:w-auto">
      <span className="text-sm font-semibold">End Date:</span>
      <input
        type="date"
        value={endDate || ""}
        onChange={(e) => setEndDate(e.target.value)}
        className="p-1.5 rounded border w-full md:w-auto"
      />
    </label>
    <label className="flex flex-col w-full md:w-auto">
      <span className="text-sm font-semibold">Policy Number:</span>
      <select
        value={policyNumber}
        onChange={(e) => setPolicyNumber(e.target.value)}
        className="p-2 rounded border w-full md:w-auto"
      >
        <option value="">All</option>
        {distinctPolicyNumbers.map((num) => (
          <option key={num} value={num.toString()}>
            {num}
          </option>
        ))}
      </select>
    </label>
    <label className="flex flex-col w-full md:w-auto">
      <span className="text-sm font-semibold">Status:</span>
      <select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        className="p-2 rounded border w-full md:w-auto"
      >
        <option value="">All</option>
        {distinctStatuses.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
    </label>
    <label className="flex flex-col w-full md:w-auto">
      <span className="text-sm font-semibold">Type:</span>
      <select
        value={type}
        onChange={(e) => setType(e.target.value)}
        className="p-2 rounded border w-full md:w-auto"
      >
        <option value="">All</option>
        {distinctTypes.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
    </label>
  </div>
</div>


      <MainTableV2
        columns={financialTransactionColumns}
        data={filteredData}
        primaryColor={primaryColor}
        loading={loading}
        alertMessage={alertMessage}
        clearAlert={clearAlert}       
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterConfig={filterConfig}
      >
        <div className="col-span-1"></div>  {/* Empty div for the first column */}
        <div className="col-span-1 flex justify-end">
        <button
          className="themed-button"
          onClick={() => handleAddTransaction()}
        >
          Add Financial Transaction
        </button>
        </div>
      </MainTableV2>
    </div>
    </>
  );
};

export default FinancialTransactionsAdmin;
