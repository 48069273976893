import React, { useState, useEffect } from 'react';

const drivers = ['Driver 1', 'Driver 2', 'Driver 3', 'Driver 4'];

const generateSampleOrders = () => {
  const sampleOrders = [
    {
      id: 1,
      customer: 'Acme Corp',
      accountNumber: 'AC12345',
      lineItems: [
        { product: 'Cleanboost CB Gold', quantity: 500, unit: 'Gallons' },
        { product: 'Diesel #2', quantity: 2000, unit: 'Gallons' }
      ],
      assignedDriver: 'Driver 1',
      status: 'Pending',
      orderDate: '2024-08-01',
      deliverByDate: '2024-08-10',
      specialRequests: 'Deliver during business hours only.',
      isOutOfProduct: false,
    },
    {
      id: 2,
      customer: 'Beta Industries',
      accountNumber: 'BI67890',
      lineItems: [
        { product: 'Syngear FG Series', quantity: 300, unit: 'Liters' }
      ],
      assignedDriver: 'Driver 2',
      status: 'Completed',
      orderDate: '2024-08-02',
      deliverByDate: '2024-08-12',
      specialRequests: 'Fill the tank completely.',
      isOutOfProduct: true,
    },
    {
      id: 3,
      customer: 'Gamma LLC',
      accountNumber: 'GL13579',
      lineItems: [
        { product: 'DEF - Diesel Exhaust Fluid', quantity: 1000, unit: 'Gallons' },
        { product: 'Gasoline - Regular', quantity: 1500, unit: 'Gallons' }
      ],
      assignedDriver: 'Driver 3',
      status: 'Pending',
      orderDate: '2024-08-03',
      deliverByDate: '2024-08-13',
      specialRequests: '',
      isOutOfProduct: false,
    },
    {
      id: 4,
      customer: 'Delta Co.',
      accountNumber: 'DC24680',
      lineItems: [
        { product: 'Varnasolv FG', quantity: 100, unit: 'Liters' }
      ],
      assignedDriver: 'Driver 4',
      status: 'Pending',
      orderDate: '2024-08-04',
      deliverByDate: '2024-08-14',
      specialRequests: 'Deliver ASAP.',
      isOutOfProduct: true,
    },
    {
      id: 5,
      customer: 'Epsilon Services',
      accountNumber: 'ES13579',
      lineItems: [
        { product: 'Antifreeze/Coolant', quantity: 200, unit: 'Drums' }
      ],
      assignedDriver: 'Driver 1',
      status: 'Completed',
      orderDate: '2024-08-05',
      deliverByDate: '2024-08-15',
      specialRequests: 'Contact before delivery.',
      isOutOfProduct: false,
    },
  ];
  return sampleOrders;
};

const OrdersComponent = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState('Pending');

  useEffect(() => {
    const storedOrders = JSON.parse(localStorage.getItem('orders'));
    if (storedOrders) {
      setOrders(storedOrders);
    }
  }, []);

  const handleAssignDriver = (orderId, driver) => {
    const updatedOrders = orders.map(order => 
      order.id === orderId ? { ...order, assignedDriver: driver } : order
    );
    setOrders(updatedOrders);
    localStorage.setItem('orders', JSON.stringify(updatedOrders));
  };

  const handleCompleteOrder = (orderId) => {
    const updatedOrders = orders.map(order => 
      order.id === orderId ? { ...order, status: 'Completed' } : order
    );
    setOrders(updatedOrders);
    localStorage.setItem('orders', JSON.stringify(updatedOrders));

    // Update inventory levels and generate invoice here
    setShowCompleteModal(false);
  };

  const handleCancelOrder = (orderId) => {
    const updatedOrders = orders.map(order => 
      order.id === orderId ? { ...order, status: 'Cancelled' } : order
    );
    setOrders(updatedOrders);
    localStorage.setItem('orders', JSON.stringify(updatedOrders));
    setShowCancelModal(false);
  };

  const viewOrderDetails = (order) => {
    setSelectedOrder(order);
    setShowOrderDetailsModal(true);
  };

  const generateOrdersData = () => {
    const sampleOrders = generateSampleOrders();
    setOrders(sampleOrders);
    localStorage.setItem('orders', JSON.stringify(sampleOrders));
  };

  const clearOrdersData = () => {
    setOrders([]);
    localStorage.removeItem('orders');
  };

  const filteredOrders = orders.filter(order => order.status === statusFilter);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Orders Management</h2>
        <div className="flex space-x-2">
          <select 
            className="border rounded px-4 py-2" 
            value={statusFilter} 
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded" 
            onClick={generateOrdersData}
          >
            Generate Sample Data
          </button>
          <button 
            className="bg-red-500 text-white px-4 py-2 rounded" 
            onClick={clearOrdersData}
          >
            Clear Data
          </button>
        </div>
      </div>
      
      <table className="w-full border border-gray-300 mb-6">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Customer</th>
            <th className="border border-gray-300 p-2">Account #</th>
            <th className="border border-gray-300 p-2">Products</th>
            <th className="border border-gray-300 p-2">Assigned Driver</th>
            <th className="border border-gray-300 p-2">Status</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map(order => (
            <tr key={order.id}>
              <td className="border border-gray-300 p-2">{order.customer}</td>
              <td className="border border-gray-300 p-2">{order.accountNumber}</td>
              <td className="border border-gray-300 p-2">
                {order.lineItems.map((item, index) => (
                  <div key={index}>
                    {item.product} - {item.quantity} {item.unit}
                  </div>
                ))}
              </td>
              <td className="border border-gray-300 p-2">
                <select 
                  className="border rounded w-full py-1 px-2" 
                  value={order.assignedDriver || ''} 
                  onChange={(e) => handleAssignDriver(order.id, e.target.value)}
                >
                  <option value="" disabled>Select Driver</option>
                  {drivers.map((driver, index) => (
                    <option key={index} value={driver}>{driver}</option>
                  ))}
                </select>
              </td>
              <td className="border border-gray-300 p-2">{order.status}</td>
              <td className="border border-gray-300 p-2">
                <button 
                  className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                  onClick={() => viewOrderDetails(order)}
                >
                  View Details
                </button>
                {order.status === 'Pending' && (
                  <>
                    <button 
                      className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                      onClick={() => {
                        setSelectedOrder(order);
                        setShowCompleteModal(true);
                      }}
                    >
                      Complete
                    </button>
                    <button 
                      className="bg-red-500 text-white px-4 py-2 rounded"
                      onClick={() => {
                        setSelectedOrder(order);
                        setShowCancelModal(true);
                      }}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

     {/* Order Details Modal */}
{showOrderDetailsModal && selectedOrder && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
    <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2">
      <h2 className="text-xl font-bold mb-4">Order Details</h2>
      <p><strong>Customer:</strong> {selectedOrder.customer}</p>
      <p><strong>Account #:</strong> {selectedOrder.accountNumber}</p>

      <h3 className="text-lg font-semibold mt-4 mb-2">Products:</h3>
      <table className="w-full border border-gray-300 mb-4">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2 text-left">Product</th>
            <th className="border border-gray-300 p-2 text-left">Quantity</th>
            <th className="border border-gray-300 p-2 text-left">Unit</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrder.lineItems.map((item, index) => (
            <tr key={index}>
              <td className="border border-gray-300 p-2">{item.product}</td>
              <td className="border border-gray-300 p-2">{item.quantity}</td>
              <td className="border border-gray-300 p-2">{item.unit}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p><strong>Date of Order:</strong> {selectedOrder.orderDate}</p>
      <p><strong>Deliver By:</strong> {selectedOrder.deliverByDate}</p>
      <p><strong>Special Requests:</strong> {selectedOrder.specialRequests || 'None'}</p>
      <p><strong>Out of Product:</strong> {selectedOrder.isOutOfProduct ? 'Yes' : 'No'}</p>
      
      <div className="flex justify-end mt-4">
        <button 
          className="bg-red-500 text-white px-4 py-2 rounded"
          onClick={() => setShowOrderDetailsModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}


      {/* Complete Order Confirmation Modal */}
      {showCompleteModal && selectedOrder && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Complete Order</h2>
            <p>Are you sure you want to mark this order as completed?</p>
            <div className="flex justify-end">
              <button 
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setShowCompleteModal(false)}
              >
                Cancel
              </button>
              <button 
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={() => handleCompleteOrder(selectedOrder.id)}
              >
                Complete Order
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Cancel Order Confirmation Modal */}
      {showCancelModal && selectedOrder && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Cancel Order</h2>
            <p>Are you sure you want to cancel this order?</p>
            <div className="flex justify-end">
              <button 
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setShowCancelModal(false)}
              >
                Cancel
              </button>
              <button 
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={() => handleCancelOrder(selectedOrder.id)}
              >
                Yes, Cancel Order
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersComponent;
