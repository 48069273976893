import React, { useState } from 'react'; 
import AchPaymentModal from './AchPaymentModal';
import { useAuth } from './AuthContext';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const BalanceCard = ({ theme, lowBalance, zeroBalance }) => {
  const [showModal, setShowModal] = useState(false);
  const { currentUser, refreshUserBalance } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const formattedBalance = currentUser.balance.toFixed(2);
  const navigate = useNavigate();

  const handleRefreshClick = async () => {
    setIsLoading(true);
    await refreshUserBalance();
    setIsLoading(false);
  };

  // const handleButtonClick = () => {
  //   setShowModal(true);
  // };

  const handleButtonClick = () => {
    if (currentUser && !currentUser.SetupComplete) {
      navigate('/paymentinfo');
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div 
      style={{          
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0.9) 0%, rgba(245,245,245,0.9) 50%, rgba(255,255,255,0.9) 100%)'
      }}
      className={`rounded-lg p-4 mb-4 flex justify-between items-center w-full`}
    >
      <div className="flex-1">
        <span className="text-xl font-semibold">
          {currentUser && currentUser.prePay ? 'Available Balance' : 'Remaining Credit'}
        </span>
        <div className="flex items-center">
          <button onClick={handleRefreshClick} className="mr-2 text-gray-700 hover:text-gray-900" title="Refresh Balance">
            <FontAwesomeIcon icon={faSync} />
          </button>
          <span className="text-3xl font-bold">${formattedBalance}</span>
        </div>
      </div>
      <div className="flex-1 text-right">
        {isLoading ? (
          <Spinner />
        ) : (
          <button
            className={`themed-button rounded-lg px-6 py-2 font-semibold ${lowBalance || zeroBalance ? 'throb' : ''}`}
            onClick={handleButtonClick}
          >
            {currentUser && currentUser.prePay ? 'Fund Account' : 'Make A Payment'}
          </button>
        )}
      </div>
      <AchPaymentModal isOpen={showModal} onClose={handleCloseModal} />
    </div>
  );
};

export default BalanceCard;
