import React, { useState, useEffect } from "react";
import { useTheme } from "./ThemeContext";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Spinner from "./Spinner";
import MobileHeader from "./MobileHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const DiscountGroupsForm = () => {
  const { theme } = useTheme();
  const NetworkId = theme.tenantId;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const location = useLocation();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();
  const [isDefault, setIsDefault] = useState(false);
  const [isPercentageMode, setIsPercentageMode] = useState(false);
  const [formData, setFormData] = useState({
    userId: currentUser.userId,
    ApplyAsPercent: 0,
    ID: id,
    DiscountGroupName: "",
    TransactionFee: "",
    IndependentsOther: "",
    PilotFJ: "",
    Loves: "",
    TAPetro: "",
    SappBrothers: "",
    Wilco: "",
    SpeedwaySuperAmerica: "",
    QuickTrip: "",
    KangarooPantry: "",
    PetroCanada: "",
    ImperialOilEsso: "",
    KwikTrip: "",
    QuickFuel: "",
    Ambest: "",
    CircleK: "",
    CatScales: "",
    ExxonMobileUSOTRNetwork: "",
    Quarles: "",
    Maverik: "",
    Irving24: "",
    RoadRanger: "",
    NFNRetail: "",
    NFNCardlock: "",
    WEXNAF: "",
    RetailUltramar: "",
    RetailChevron: "",
    RetailPioneer: "",
    RetailCoucheTard: "",
    RetailFasGas: "",
    RetailRaceTrac: "",
    CardlockChevron: "",
    CardlockColumbiaFuels: "",
    CardlockBluewave: "",
    CardlockPipeline: "",
    CardlockUltramar: "",
    EclipseAdvantageLLC: "",
    Capstone: "",
    Roadsync: "",
    ScFuels: "",
    Racetrac: "",
    Caseys: "",
    LimitDiscounts: 1,
    Diesel: 0,
    Gas: 0,
    DEF: 0,
    ReeferFuel: 0,
    LNG: 0
  });

  const discountFields = [
    "Ambest",
    "Capstone",
    "Caseys",
    "CatScales",
    "CircleK",
    "EclipseAdvantageLLC",
    "ExxonMobileUSOTRNetwork",
    "ImperialOilEsso",
    "IndependentsOther",
    "Irving24",
    "KangarooPantry",
    "KwikTrip",
    "Loves",
    "Maverik",
    "NFNCardlock",
    "NFNRetail",
    "PilotFJ",
    "QuickFuel",
    "QuickTrip",
    "Quarles",
    "Racetrac",
    "RoadRanger",
    "Roadsync",
    "SappBrothers",
    "ScFuels",
    "SpeedwaySuperAmerica",
    "TAPetro",
    "Wilco",
    "WEXNAF",
    // Retail and Cardlock chains
    "RetailChevron",
    "RetailCoucheTard",
    "RetailFasGas",
    "RetailPioneer",
    "RetailRaceTrac",
    "RetailUltramar",
    "CardlockBluewave",
    "CardlockChevron",
    "CardlockColumbiaFuels",
    "CardlockPipeline",
    "CardlockUltramar"
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    let adjustedValue = value;

    // Convert to number if it's a decimal field
    if (discountFields.includes(name)) {
      adjustedValue = value ? parseFloat(value) : 0; // Convert to float or default to 0
      if (isPercentageMode) {
        adjustedValue = Math.min(Math.max(adjustedValue, 0), 100); // Clamp between 0 and 100
      }
    }

    setFormData({
      ...formData,
      [name]: adjustedValue,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const apiUrl = `https://fuellink-db-ui.azurewebsites.net/api/getExistingDiscountGroupRecord?`;

        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ID: id,
              userId: currentUser.userId,
              NetworkId: NetworkId,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Discount Group Data: ", data);
            // Populate the form with the data received
            setFormData(prevFormData => ({
              ...prevFormData,
              ...data,
              Diesel: data.Diesel ?? 0,
              Gas: data.Gas ?? 0,
              DEF: data.DEF ?? 0,
              ReeferFuel: data.ReeferFuel ?? 0,
              LNG: data.LNG ?? 0,
              LimitDiscounts: data.LimitDiscounts ?? 1
            }));

            // Check if the DiscountGroupName is "Default"
            if (data.DiscountGroupName === "Default") {
              setIsDefault(true);
            }
          }
        } catch (error) {
          console.error("There was a problem fetching the data:", error);
        }
      }
    };

    // Call the fetch function
    fetchData();
  }, [id, currentUser.userId, NetworkId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIsLoading(true);

    console.log("Form Data on Submit:", formData);

    const apiUrl = `https://fuellink-db-ui.azurewebsites.net/api/setDiscountGroupData?`;
    const httpMethod = "POST";

    try {
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        let data;

        if (contentType && contentType.indexOf("application/json") !== -1) {
          data = await response.json();
        } else {
          data = await response.text();
        }
        setAlertMessage("Success! ", data);        
      } else {
        let errorData;

        if (contentType && contentType.indexOf("application/json") !== -1) {
          errorData = await response.json();
          setAlertMessage(errorData);
        } else {
          errorData = await response.text();
          setAlertMessage(errorData);
        }

        console.error("Error:", errorData);
        // Show an error message
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      // Show an error message
    } finally {
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <MobileHeader theme={theme} />
      
      <div className="mobile-container space-y-8 p-4">
        {isLoading && <Spinner />}
        <div className="text-end mb-4">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="text-gray-700 text-sm mr-4"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
            Go Back
          </button>
        </div>
        {alertMessage && (
          <div
            className={`alert p-4 rounded ${alertMessage.startsWith("Success!")
              ? "bg-green-200 text-green-600"
              : "bg-red-200 text-red-600"
              } flex justify-between items-center`}
          >
            <span>{alertMessage}</span>
            <button onClick={() => setAlertMessage("")}>Close</button>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Text Fields */}
          <div>
            {/* Discount Group Name */}
            <label className="block text-sm font-medium text-gray-700">
              Discount Group Name
            </label>
            <input
              type="text"
              name="DiscountGroupName"
              onChange={handleChange}
              readOnly={isDefault && formData.DiscountGroupName === "Default"}
              value={formData.DiscountGroupName}
              className="mt-1 p-2 w-full border rounded-md"
              required
            />
            <div className="mt-2 flex items-center space-x-2">
              <input
                type="checkbox"
                name="LimitDiscounts"
                onChange={(e) => setFormData({ ...formData, LimitDiscounts: e.target.checked ? 1 : 0 })}
                checked={formData.LimitDiscounts}
                className="mr-2"
                id="LimitDiscounts"
              />
              <label htmlFor="LimitDiscounts">Limit Discounts?</label>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              Limit Discounts to your maximum discount, if not selected you may incur losses on some transactions where user discounts are greater than your discount.
            </p>
            <div className="mt-2 flex items-center space-x-2">
              <input
                type="checkbox"
                name="isPercentageMode"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setIsPercentageMode(isChecked); // Update the isPercentageMode state
                  setFormData({ ...formData, ApplyAsPercent: isChecked ? 1 : 0 }); // Update formData state
                }}
                checked={formData.ApplyAsPercent}
                className="mr-2"
                id="isPercentageMode"
              />
              <label htmlFor="isPercentageMode">Apply Discounts as Percentage?</label>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              Apply discounts as a percentage of the total discount, instead of using fixed amounts.
            </p>

            {/* Divider */}
            <hr className="my-4" />

            {/* Apply Discounts To Selected Products */}
            <div className="flex flex-wrap mb-4">
              <h3 className="text-xl font-bold w-full">Apply Discounts To:</h3>
              {["Diesel", "Gas", "DEF", "ReeferFuel", "LNG"].map((product) => (
                <div key={product} className="flex items-center space-x-2 w-1/2 md:w-1/5">
                  <input
                    type="checkbox"
                    name={product}
                    onChange={(e) => setFormData({ ...formData, [product]: e.target.checked ? 1 : 0 })}
                    checked={formData[product]}
                    className="mr-2"
                    id={product}
                  />
                  <label htmlFor={product}>{product}</label>
                </div>
              ))}
            </div>

            <hr className="my-4" />

            {/* Transaction Fee */}
            <label className="block text-sm font-medium text-gray-700">
              Transaction Fee
            </label>
            <input
              type="number"
              name="TransactionFee"
              min="0"
              step="0.01"
              onChange={handleChange}
              value={formData.TransactionFee}
              className={`mt-1 p-2 w-full border rounded-md ${formData.TransactionFee > 0 ? 'text-highlight' : ''}`}
            />
            {/* Divider */}
            <hr className="my-4" />
          </div>

          <div className="flex flex-wrap">
            {discountFields.map((field, index) => (
              <div key={index} className="w-full md:w-1/3 p-2">
                <label className="block text-sm font-medium text-gray-700">
                  {field.replace(/([A-Z])/g, " $1")}
                </label>
                <input
                  type="number"
                  name={field}
                  min="0"
                  max={isPercentageMode ? "100" : ""}
                  onChange={handleChange}
                  value={formData[field]}
                  step="0.01"
                  placeholder={isPercentageMode ? "Percentage" : "Amount"}
                  className={`mt-1 p-2 w-full border rounded-md ${formData[field] > 0 ? 'text-highlight' : ''}`}
                />
              </div>
            ))}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 text-white rounded themed-button"
          >
            {id === null ? "Create" : "Update"}
          </button>
        </form>
        <div className="text-end mb-4">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="text-gray-700 text-sm mr-4"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DiscountGroupsForm;



// import React, { useState, useEffect } from "react";
// import { useTheme } from "./ThemeContext";
// import { useParams, useLocation, useNavigate } from "react-router-dom";
// import { useAuth } from "./AuthContext";
// import Spinner from "./Spinner";
// import MobileHeader from "./MobileHeader";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// const DiscountGroupsForm = () => {
//   const { theme } = useTheme();
//   const NetworkId = theme.tenantId;
//   const query = new URLSearchParams(useLocation().search);
//   const id = query.get("id");
//   const location = useLocation();
//   const { currentUser } = useAuth();
//   const [isLoading, setIsLoading] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");
//   const navigate = useNavigate();
//   const [isDefault, setIsDefault] = useState(false);
//   const [isPercentageMode, setIsPercentageMode] = useState(false);
//   const [formData, setFormData] = useState({
//     userId: currentUser.userId,
//     ApplyAsPercent: 0,
//     ID: id,
//     DiscountGroupName: "",
//     TransactionFee: "",
//     IndependentsOther: "",
//     PilotFJ: "",
//     Loves: "",
//     TAPetro: "",
//     SappBrothers: "",
//     Wilco: "",
//     SpeedwaySuperAmerica: "",
//     QuickTrip: "",
//     KangarooPantry: "",
//     PetroCanada: "",
//     ImperialOilEsso: "",
//     KwikTrip: "",
//     QuickFuel: "",
//     Ambest: "",
//     CircleK: "",
//     CatScales: "",
//     ExxonMobileUSOTRNetwork: "",
//     Quarles: "",
//     Maverik: "",
//     Irving24: "",
//     RoadRanger: "",
//     NFNRetail: "",
//     NFNCardlock: "",
//     WEXNAF: "",
//     RetailUltramar: "",
//     RetailChevron: "",
//     RetailPioneer: "",
//     RetailCoucheTard: "",
//     RetailFasGas: "",
//     RetailRaceTrac: "",
//     CardlockChevron: "",
//     CardlockColumbiaFuels: "",
//     CardlockBluewave: "",
//     CardlockPipeline: "",
//     CardlockUltramar: "",
//     EclipseAdvantageLLC: "",
//     Capstone: "",
//     Roadsync: "",
//     ScFuels: "",
//     Racetrac: "",
//     Caseys: "",
//     LimitDiscounts: 1,
//     Diesel: 0,
//     Gas: 0,
//     DEF: 0,
//     ReeferFuel: 0,
//     LNG: 0
//   });

//   const discountFields = [
//     "Ambest",
//     "Capstone",
//     "Caseys",
//     "CatScales",
//     "CircleK",
//     "EclipseAdvantageLLC",
//     "ExxonMobileUSOTRNetwork",
//     "ImperialOilEsso",
//     "IndependentsOther",
//     "Irving24",
//     "KangarooPantry",
//     "KwikTrip",
//     "Loves",
//     "Maverik",
//     "NFNCardlock",
//     "NFNRetail",
//     "PilotFJ",
//     "QuickFuel",
//     "QuickTrip",
//     "Quarles",
//     "Racetrac",
//     "RoadRanger",
//     "Roadsync",
//     "SappBrothers",
//     "ScFuels",
//     "SpeedwaySuperAmerica",
//     "TAPetro",
//     "Wilco",
//     "WEXNAF",
//     // Retail and Cardlock chains
//     "RetailChevron",
//     "RetailCoucheTard",
//     "RetailFasGas",
//     "RetailPioneer",
//     "RetailRaceTrac",
//     "RetailUltramar",
//     "CardlockBluewave",
//     "CardlockChevron",
//     "CardlockColumbiaFuels",
//     "CardlockPipeline",
//     "CardlockUltramar"
//   ];
  

//   // const discountFields = [
//   //   "IndependentsOther",
//   //   "PilotFJ",
//   //   "Loves",
//   //   "TAPetro",
//   //   "SappBrothers",
//   //   "Wilco",
//   //   "SpeedwaySuperAmerica",
//   //   "QuickTrip",
//   //   "KangarooPantry",
//   //   "PetroCanada",
//   //   "ImperialOilEsso",
//   //   "KwikTrip",
//   //   "QuickFuel",
//   //   "Ambest",
//   //   "CircleK",
//   //   "EclipseAdvantageLLC",
//   //   "Capstone",
//   //   "Roadsync",
//   //   "ScFuels",
//   //   "Racetrac",
//   //   "Caseys",
//   //   "CatScales",
//   //   "ExxonMobileUSOTRNetwork",
//   //   "Quarles",
//   //   "Maverik",
//   //   "Irving24",
//   //   "RoadRanger",
//   //   "NFNRetail",
//   //   "NFNCardlock",    
//   //   "WEXNAF",
//   //   "RetailUltramar",
//   //   "RetailChevron",
//   //   "RetailPioneer",
//   //   "RetailCoucheTard",
//   //   "RetailFasGas",
//   //   "RetailRaceTrac",
//   //   "CardlockChevron",
//   //   "CardlockColumbiaFuels",
//   //   "CardlockBluewave",
//   //   "CardlockPipeline",
//   //   "CardlockUltramar"    
//   // ];

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     let adjustedValue = value;
  
//     // Convert to number if it's a decimal field
//     if (discountFields.includes(name)) {
//       adjustedValue = value ? parseFloat(value) : 0; // Convert to float or default to 0
//       if (isPercentageMode) {
//         adjustedValue = Math.min(Math.max(adjustedValue, 0), 100); // Clamp between 0 and 100
//       }
//     }
  
//     setFormData({
//       ...formData,
//       [name]: adjustedValue,
//     });
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       if (id) {
//         const apiUrl = `https://fuellink-db-ui.azurewebsites.net/api/getExistingDiscountGroupRecord?`;

//         try {
//           const response = await fetch(apiUrl, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               ID: id,
//               userId: currentUser.userId,
//               NetworkId: NetworkId,
//             }),
//           });

//           if (response.ok) {
//             const data = await response.json();
//             console.log("Discount Group Data: ", data);
//             // Populate the form with the data received
//             setFormData(prevFormData => ({
//               ...prevFormData,
//               ...data,
//               Diesel: data.Diesel ?? 0,
//               Gas: data.Gas ?? 0,
//               DEF: data.DEF ?? 0,
//               ReeferFuel: data.ReeferFuel ?? 0,
//               LNG: data.LNG ?? 0,
//               LimitDiscounts: data.LimitDiscounts ?? 1
//             }));

//             // Check if the DiscountGroupName is "Default"
//             if (data.DiscountGroupName === "Default") {
//               setIsDefault(true);
//             }
//           }
//         } catch (error) {
//           console.error("There was a problem fetching the data:", error);
//         }
//       }
//     };

//     // Call the fetch function
//     fetchData();
//   }, [id, currentUser.userId, NetworkId]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     window.scrollTo(0, 0);
//     setIsLoading(true);

//     console.log("Form Data on Submit:", formData);

//     const apiUrl = `https://fuellink-db-ui.azurewebsites.net/api/setDiscountGroupData?`;
//     const httpMethod = "POST";

//     try {
//       const response = await fetch(apiUrl, {
//         method: httpMethod,
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       const contentType = response.headers.get("content-type");

//       if (response.ok) {
//         let data;

//         if (contentType && contentType.indexOf("application/json") !== -1) {
//           data = await response.json();
//         } else {
//           data = await response.text();
//         }
//         setAlertMessage("Success! ", data);

//         setTimeout(() => {
//           navigate("/discount-groups");
//         }, 1500);
//       } else {
//         let errorData;

//         if (contentType && contentType.indexOf("application/json") !== -1) {
//           errorData = await response.json();
//           setAlertMessage(errorData);
//         } else {
//           errorData = await response.text();
//           setAlertMessage(errorData);
//         }

//         console.error("Error:", errorData);
//         // Show an error message
//       }
//     } catch (error) {
//       console.error("There was a problem with the fetch operation:", error);
//       // Show an error message
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <>
//     <MobileHeader theme={theme} />
    
//     <div className="mobile-container space-y-8">
//       {isLoading && <Spinner />}
//       <div className="text-end mb-4">
//         <button
//           type="button"
//           onClick={() => navigate(-1)}
//           className="text-gray-700 text-sm mr-4"
//         >
//           <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
//           Go Back
//         </button>
//       </div>
//       {alertMessage && (
//         <div
//           className={`alert p-4 rounded ${alertMessage.startsWith("Success!")
//             ? "bg-green-200 text-green-600"
//             : "bg-red-200 text-red-600"
//             } flex justify-between items-center`}
//         >
//           <span>{alertMessage}</span>
//           <button onClick={() => setAlertMessage("")}>Close</button>
//         </div>
//       )}

//       <form onSubmit={handleSubmit} className="space-y-4">
//         {/* Text Fields */}
//         <div>
//           {/* Discount Group Name */}
//           <label className="block text-sm font-medium text-gray-700">
//             Discount Group Name
//           </label>
//           <input
//             type="text"
//             name="DiscountGroupName"
//             onChange={handleChange}
//             readOnly={isDefault && formData.DiscountGroupName === "Default"}
//             value={formData.DiscountGroupName}
//             className="mt-1 p-2 w-full border rounded-md"
//             required
//           />
//           <div className="mt-2 flex items-center space-x-2">
//             <input
//               type="checkbox"
//               name="LimitDiscounts"
//               onChange={(e) => setFormData({ ...formData, LimitDiscounts: e.target.checked ? 1 : 0 })}
//               checked={formData.LimitDiscounts}
//               className="mr-2"
//               id="LimitDiscounts"
//             />
//             <label htmlFor="LimitDiscounts">Limit Discounts?</label>
//           </div>
//           <p className="text-sm text-gray-600 mb-4">
//             Limit Discounts to your maximum discount, if not selected you may incur losses on some transactions where user discounts are greater than your discount.
//           </p>
//           <div className="mt-2 flex items-center space-x-2">
//             <input
//               type="checkbox"
//               name="isPercentageMode"
//               onChange={(e) => {
//                 const isChecked = e.target.checked;
//                 setIsPercentageMode(isChecked); // Update the isPercentageMode state
//                 setFormData({ ...formData, ApplyAsPercent: isChecked ? 1 : 0 }); // Update formData state
//               }}
//               checked={formData.ApplyAsPercent}
//               className="mr-2"
//               id="isPercentageMode"
//             />
//             <label htmlFor="isPercentageMode">Apply Discounts as Percentage?</label>
//           </div>
//           <p className="text-sm text-gray-600 mb-4">
//             Apply discounts as a percentage of the total discount, instead of using fixed amounts.
//           </p>

//           {/* Divider */}
//           <hr className="my-4" />

//           {/* Apply Discounts To Selected Products */}
//           <div className="flex space-x-6 mb-4">
//             <h3 className="text-xl font-bold mr-4">Apply Discounts To:</h3>
//             {["Diesel", "Gas", "DEF", "ReeferFuel", "LNG"].map((product) => (
//               <div key={product} className="flex items-center space-x-2">
//                 <input
//                   type="checkbox"
//                   name={product}
//                   onChange={(e) => setFormData({ ...formData, [product]: e.target.checked ? 1 : 0 })}
//                   checked={formData[product]}
//                   className="mr-2"
//                   id={product}
//                 />
//                 <label htmlFor={product}>{product}</label>
//               </div>
//             ))}
//           </div>

//           <hr className="my-4" />

//           {/* Transaction Fee */}
//           <label className="block text-sm font-medium text-gray-700">
//             Transaction Fee
//           </label>
//           <input
//             type="number"
//             name="TransactionFee"
//             min="0"
//             step="0.01"
//             onChange={handleChange}
//             value={formData.TransactionFee}
//             className={`mt-1 p-2 w-full border rounded-md ${formData.TransactionFee > 0 ? 'text-highlight' : ''}`}
//           />
//           {/* Divider */}
//           <hr className="my-4" />
//         </div>

//         <div className="flex flex-wrap">
//           {discountFields.map((field, index) => (
//             <div key={index} className="w-1/3 p-2">
//               <label className="block text-sm font-medium text-gray-700">
//                 {field.replace(/([A-Z])/g, " $1")}
//               </label>
//               <input
//                 type="number"
//                 name={field}
//                 min="0"
//                 max={isPercentageMode ? "100" : ""}
//                 onChange={handleChange}
//                 value={formData[field]}
//                 step="0.01"
//                 placeholder={isPercentageMode ? "Percentage" : "Amount"}
//                 className={`mt-1 p-2 w-full border rounded-md ${formData[field] > 0 ? 'text-highlight' : ''}`}
//               />
//             </div>
//           ))}
//         </div>

//         {/* Submit Button */}
//         <button
//           type="submit"
//           className="mt-4 px-4 py-2 text-white rounded themed-button"
//         >
//           {id === null ? "Create" : "Update"}
//         </button>
//       </form>
//       <div className="text-end mb-4">
//         <button
//           type="button"
//           onClick={() => navigate(-1)}
//           className="text-gray-700 text-sm mr-4"
//         >
//           <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
//           Go Back
//         </button>
//       </div>
//     </div>
//     </>
//   );
// };

// export default DiscountGroupsForm;
