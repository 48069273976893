import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import Spinner from './Spinner'; // Import Spinner component
import { Link } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from "firebase/auth";

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();
  const apiUrl = process.env.REACT_APP_API_URL;
  const subDomain = window.location.hostname.split('.')[0];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('inviteCode');
    if (code) {
      setInviteCode(code);
    }
  }, [location]);

  const handleSignUpWithEmail = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading spinner
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false); // Stop loading spinner
      return;
    }

    try {
      // Call your function app API here
      const response = await fetch(`${apiUrl}/FuelLink-InviteCodeRedemption?`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteCode,
          email,
          password,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        const auth = getAuth();
        // Assuming result.customToken contains the token
        await signInWithCustomToken(auth, result.customToken);
        navigate('/dashboard');
      } else {
        setError(result.error || 'An error occurred'); // Use the response text as the error message
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const logoClass = theme.logoSize === 'small' ? 'w-1/4' : theme.logoSize === 'medium' ? 'w-4/5' : 'w-full';

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {loading && <Spinner />}
      <div className="flex-1 flex items-center justify-center bg-white">
          <div className="text-center justify-center">
            <img src={theme.loginImage} alt="Logo" className={`${logoClass} h-auto mx-auto`} />
            {theme.onBehalfOf && (
              <p className="mt-2 text-sm text-gray-700">
                A FuelLink Portal created for <strong>{theme.onBehalfOf}</strong>. <a href="https://www.fuellink.net" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Learn more</a>
              </p>
            )}
          </div>
        </div>

      <div className="flex-1 flex items-center justify-center p-4" style={{ backgroundColor: theme.loginPrimaryBG }}>
        <div
          style={{
            background: 'rgba(245, 245, 245, 0.9)', // Off-white background
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
          }}
          className="p-8 rounded-lg w-96"
        >
          <h2 className="text-2xl mb-4 text-center">Redeem Invite</h2>
          {loading && <Spinner />} {/* Display spinner when loading */}
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSignUpWithEmail}>
            <div className="mb-4">
              <input
                type="text"
                id="inviteCode"
                name="inviteCode"
                placeholder="Invite Code"
                value={inviteCode}
                onChange={(e) => setInviteCode(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="password"
                placeholder="Create Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <button
              type="submit"              
              className="w-full themed-login-button text-white p-2 rounded mb-4"
            >
              Redeem Invitation
            </button>
          </form>
          <div className="text-center">

            <div>
              <Link to="/" className="text-blue-500 hover:underline">Already have an account? Login</Link>
            </div>

            <div>
              <Link to="/forgot-password" className="text-blue-500 hover:underline">Forgot Password?</Link>
            </div>
            {subDomain !== 'fbt' && (
              <div>
                <Link to="/registration" className="text-blue-500 hover:underline">Don't have an invitation code? Sign Up</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
