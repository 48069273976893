import React, { createContext, useContext, useState, useEffect } from 'react';
import { getThemeForDomain } from './themes';
import { setTenantId } from './firebase';

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const currentDomain = window.location.hostname;
  const [theme, setTheme] = useState(getThemeForDomain(currentDomain));

  useEffect(() => {
    setTenantId(theme.tenantId);
    document.documentElement.style.setProperty('--primary-color', theme.primary);
    document.documentElement.style.setProperty('--secondary-color', theme.secondary);
    document.documentElement.style.setProperty('--primary-text', theme.primaryText);
    document.documentElement.style.setProperty('--secondary-text', theme.secondaryText);
    document.documentElement.style.setProperty('--menu-text', theme.menuText);
    document.documentElement.style.setProperty('--BtnTxtPrimaryColor', theme.BtnTxtPrimaryColor);
    document.documentElement.style.setProperty('--BtnTxtHoverColor', theme.BtnTxtHoverColor);
    document.documentElement.style.setProperty('--marker-icon', theme.markerIcon || '');
    document.documentElement.style.setProperty('--login-primary-bg', theme.loginPrimaryBG);
    document.documentElement.style.setProperty('--login-btn-primary', theme.loginBtnPrimary);
    document.documentElement.style.setProperty('--login-btn-hover', theme.loginBtnHover);
    if (theme.onBehalfOf) {
      document.documentElement.style.setProperty('--on-behalf-of', theme.onBehalfOf);
    }
  }, [theme]);

  const switchTheme = (newTheme) => {
    setTheme(newTheme);
    document.documentElement.style.setProperty('--primary-color', newTheme.primary);
    document.documentElement.style.setProperty('--secondary-color', newTheme.secondary);
    document.documentElement.style.setProperty('--login-primary-bg', theme.loginPrimaryBG);
    document.documentElement.style.setProperty('--login-btn-primary', theme.loginBtnPrimary);
    document.documentElement.style.setProperty('--login-btn-hover', theme.loginBtnHover);
    if (newTheme.onBehalfOf) {
      document.documentElement.style.setProperty('--on-behalf-of', newTheme.onBehalfOf);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};



// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { getThemeForDomain } from './themes';  // Import the function from theme.js
// import { setTenantId } from './firebase';  // Import the function to set tenantId

// const ThemeContext = createContext();

// export const useTheme = () => {
//   return useContext(ThemeContext);
// };

// export const ThemeProvider = ({ children }) => {
//   const currentDomain = window.location.hostname;
//   const [theme, setTheme] = useState(getThemeForDomain(currentDomain));  // Use the function to get the theme

//   useEffect(() => {
//     setTenantId(theme.tenantId);  // Set the tenantId in Firebase
//     document.documentElement.style.setProperty('--primary-color', theme.primary);
//     document.documentElement.style.setProperty('--secondary-color', theme.secondary);
//     document.documentElement.style.setProperty('--primary-text', theme.primaryText);
//     document.documentElement.style.setProperty('--secondary-text', theme.secondaryText);
//     document.documentElement.style.setProperty('--menu-text', theme.menuText);
//     document.documentElement.style.setProperty('--BtnTxtPrimaryColor', theme.BtnTxtPrimaryColor);
//     document.documentElement.style.setProperty('--BtnTxtHoverColor', theme.BtnTxtHoverColor);
//     document.documentElement.style.setProperty('--marker-icon', theme.markerIcon || '');
//     document.documentElement.style.setProperty('--login-primary-bg', theme.loginPrimaryBG);
//     document.documentElement.style.setProperty('--login-btn-primary', theme.loginBtnPrimary);
//     document.documentElement.style.setProperty('--login-btn-hover', theme.loginBtnHover);
//   }, [theme]);
  

//   const switchTheme = (newTheme) => {
//     setTheme(newTheme);
//     document.documentElement.style.setProperty('--primary-color', newTheme.primary);
//     document.documentElement.style.setProperty('--secondary-color', newTheme.secondary);
//     document.documentElement.style.setProperty('--login-primary-bg', theme.loginPrimaryBG);
//     document.documentElement.style.setProperty('--login-btn-primary', theme.loginBtnPrimary);
//     document.documentElement.style.setProperty('--login-btn-hover', theme.loginBtnHover);
//   };

//   return (
//     <ThemeContext.Provider value={{ theme, switchTheme }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };
 