import React, { useState, useEffect, useContext } from 'react';
import { signInWithRedirect, getRedirectResult, signInWithEmailAndPassword } from "firebase/auth";
import { auth, googleProvider, setTenantId, sendPasswordResetEmail } from './firebase.js';
import { useDispatch } from 'react-redux';
import { login } from './userSlice';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useTheme } from './ThemeContext';  
import Spinner from './Spinner.js';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, loginWithEmail } = useContext(AuthContext);
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const subDomain = window.location.hostname.split('.')[0];

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Set a timeout to hide the spinner and show an error message
    const loginTimeout = setTimeout(() => {
      setIsLoading(false);
      setError('Login process is taking too long. Please try again.');
    }, 15000); // 15 seconds timeout

    try {
      await loginWithEmail(email, password);
      clearTimeout(loginTimeout); // Clear the timeout if login is successful
    } catch (err) {
      console.error("Error Logging In:", err);
      console.error("Error Response:", err.response ? err.response.data : "No response data");
      setError(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      getRedirectResult(auth)
        .then((result) => {
          if (result && result.user) {
            const { displayName, email, uid, photoURL } = result.user;
            dispatch(login({ displayName, email, uid, photoURL }));
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          console.error("Error in getRedirectResult:", error);
          setError(error.message);
        });
    }
  }, [currentUser, dispatch, navigate]);

  const logoClass = theme.logoSize === 'small' ? 'w-1/4' : theme.logoSize === 'medium' ? 'w-4/5' : 'w-full';

  return (
    <>
      {isLoading && <Spinner />}
      <div className="min-h-screen flex flex-col md:flex-row">
        <div className="flex-1 flex items-center justify-center bg-white">
          <div className="text-center justify-center">
            <img src={theme.loginImage} alt="Logo" className={`${logoClass} h-auto mx-auto`} />
            {theme.onBehalfOf && (
              <p className="mt-2 text-sm text-gray-700">
                A FuelLink Portal created for <strong>{theme.onBehalfOf}</strong>. <a href="https://www.fuellink.net" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Learn more</a>
              </p>
            )}
          </div>
        </div>
        <div className="flex-1 flex items-center justify-center p-4" style={{ backgroundColor: theme.loginPrimaryBG }}>
          <div
            style={{
              background: 'rgba(245, 245, 245, 0.9)',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}
            className="p-8 rounded-lg w-96"
          >
            <h2 className="text-2xl mb-4 text-center">Login</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <button
                type="submit"
                className="w-full themed-login-button p-2 rounded mb-4"
              >
                Login
              </button>
            </form>

            <div className="text-center">
            {subDomain !== 'fbt' && (
              <div>
                <Link to="/registration" className="text-blue-500 hover:underline">Sign Up</Link>
              </div>
            )}
              <div>
                <Link to="/resetpassword" className="text-blue-500 hover:underline">Forgot Password?</Link>
              </div>
              {subDomain !== 'fbt' && (
              <div>
                <Link to="/faq" className="text-blue-500 hover:underline">FAQ's</Link>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;



// import React, { useState, useEffect, useContext } from 'react';
// import { signInWithRedirect, getRedirectResult, signInWithEmailAndPassword } from "firebase/auth";
// import { auth, googleProvider, setTenantId, sendPasswordResetEmail } from './firebase.js';
// import { useDispatch } from 'react-redux';
// import { login } from './userSlice';
// import { useNavigate, Link } from 'react-router-dom';
// import { AuthContext } from './AuthContext';
// import { useTheme } from './ThemeContext';  
// import Spinner from './Spinner.js';

// function Login() {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { currentUser, loginWithEmail } = useContext(AuthContext);
//   const { theme } = useTheme();
//   const [isLoading, setIsLoading] = useState(false);

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     // Set a timeout to hide the spinner and show an error message
//     const loginTimeout = setTimeout(() => {
//       setIsLoading(false);
//       setError('Login process is taking too long. Please try again.');
//     }, 15000); // 15 seconds timeout

//     try {
//       await loginWithEmail(email, password);
//       clearTimeout(loginTimeout); // Clear the timeout if login is successful
//     } catch (err) {
//       console.error("Error Logging In:", err);
//       console.error("Error Response:", err.response ? err.response.data : "No response data");
//       setError(err.message);
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (currentUser) {
//       navigate('/dashboard');
//     } else {
//       getRedirectResult(auth)
//         .then((result) => {
//           if (result && result.user) {
//             const { displayName, email, uid, photoURL } = result.user;
//             dispatch(login({ displayName, email, uid, photoURL }));
//             navigate('/dashboard');
//           }
//         })
//         .catch((error) => {
//           console.error("Error in getRedirectResult:", error);
//           setError(error.message);
//         });
//     }
//   }, [currentUser, dispatch, navigate]);

//   const logoClass = theme.logoSize === 'small' ? 'w-1/4' : theme.logoSize === 'medium' ? 'w-1/2' : 'w-full';

//   return (
//     <>
//       {isLoading && <Spinner />}
//       <div className="min-h-screen flex flex-col md:flex-row">
//         <div className="flex-1 flex items-center justify-center bg-white">
//           <img src={theme.loginImage} alt="Logo" className={`${logoClass} h-auto`} />
//         </div>
//         <div className="flex-1 flex items-center justify-center p-4" style={{ backgroundColor: theme.loginPrimaryBG }}>
//           <div
//             style={{
//               background: 'rgba(245, 245, 245, 0.9)',
//               boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
//             }}
//             className="p-8 rounded-lg w-96"
//           >
//             <h2 className="text-2xl mb-4 text-center">Login</h2>
//             {error && <p className="text-red-500 mb-4">{error}</p>}
//             <form onSubmit={handleLogin}>
//               <div className="mb-4">
//                 <input
//                   type="email"
//                   placeholder="Email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   className="w-full p-2 border rounded"
//                 />
//               </div>
//               <div className="mb-4">
//                 <input
//                   type="password"
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   className="w-full p-2 border rounded"
//                 />
//               </div>
//               <button
//                 type="submit"
//                 className="w-full themed-login-button p-2 rounded mb-4"
//               >
//                 Login
//               </button>
//             </form>

//             <div className="text-center">
//               <div>
//                 <Link to="/registration" className="text-blue-500 hover:underline">Sign Up</Link>
//               </div>
//               <div>
//                 <Link to="/resetpassword" className="text-blue-500 hover:underline">Forgot Password?</Link>
//               </div>
//               <div>
//                 <Link to="/faq" className="text-blue-500 hover:underline">FAQ's</Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Login;