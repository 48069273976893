import React, { useState, useEffect } from 'react';

const generateSampleLeads = () => {
  const sampleLeads = [
    { id: 1, businessName: 'ABC Logistics', contactName: 'John Doe', phone: '555-123-4567', email: 'johndoe@abc.com', status: 'New', notes: '', assignedTo: '' },
    { id: 2, businessName: 'XYZ Trucking', contactName: 'Jane Smith', phone: '555-234-5678', email: 'janesmith@xyz.com', status: 'Contacted', notes: '', assignedTo: '' },
    { id: 3, businessName: 'Mega Haul', contactName: 'Bob Johnson', phone: '555-345-6789', email: 'bobjohnson@mega.com', status: 'Qualified', notes: '', assignedTo: '' },
    { id: 4, businessName: 'Fuel Co.', contactName: 'Alice Brown', phone: '555-456-7890', email: 'alicebrown@fuelco.com', status: 'Proposal Sent', notes: '', assignedTo: '' },
    { id: 5, businessName: 'Fast Freight', contactName: 'Charlie Davis', phone: '555-567-8901', email: 'charliedavis@fastfreight.com', status: 'Closed Won', notes: '', assignedTo: '' },
  ];
  return sampleLeads;
};

const salespersons = [
  'John Smith',
  'Mary Johnson',
  'Robert Brown',
  'Patricia Garcia',
  'Linda Martinez'
];

const CRMComponent = () => {
  const [leads, setLeads] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [showAddLeadModal, setShowAddLeadModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  useEffect(() => {
    const storedLeads = JSON.parse(localStorage.getItem('leads'));
    if (storedLeads) {
      setLeads(storedLeads);
    }
  }, []);

  useEffect(() => {
    if (statusFilter) {
      setFilteredLeads(leads.filter(lead => lead.status === statusFilter));
    } else {
      setFilteredLeads(leads);
    }
  }, [statusFilter, leads]);

  const handleStatusChange = (id, newStatus) => {
    const updatedLeads = leads.map(lead => 
      lead.id === id ? { ...lead, status: newStatus } : lead
    );
    setLeads(updatedLeads);
    localStorage.setItem('leads', JSON.stringify(updatedLeads));
  };

  const handleNotesChange = (id, newNotes) => {
    const updatedLeads = leads.map(lead => 
      lead.id === id ? { ...lead, notes: newNotes } : lead
    );
    setLeads(updatedLeads);
    localStorage.setItem('leads', JSON.stringify(updatedLeads));
  };

  const handleAssignedToChange = (id, newAssignedTo) => {
    const updatedLeads = leads.map(lead => 
      lead.id === id ? { ...lead, assignedTo: newAssignedTo } : lead
    );
    setLeads(updatedLeads);
    localStorage.setItem('leads', JSON.stringify(updatedLeads));
  };

  const generateData = () => {
    const sampleLeads = generateSampleLeads();
    setLeads(sampleLeads);
    localStorage.setItem('leads', JSON.stringify(sampleLeads));
  };

  const clearData = () => {
    setLeads([]);
    localStorage.removeItem('leads');
  };

  const handleAddLeadSubmit = (e) => {
    e.preventDefault();
    const newLead = {
      id: leads.length + 1,
      businessName: e.target.businessName.value,
      contactName: e.target.contactName.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      status: 'New',
      notes: '',
      assignedTo: ''
    };
    const updatedLeads = [...leads, newLead];
    setLeads(updatedLeads);
    localStorage.setItem('leads', JSON.stringify(updatedLeads));
    setShowAddLeadModal(false);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <label className="block text-gray-700 mr-4">Filter by Status</label>
          <select 
            className="border rounded py-2 px-3" 
            value={statusFilter} 
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="New">New</option>
            <option value="Contacted">Contacted</option>
            <option value="Qualified">Qualified</option>
            <option value="Proposal Sent">Proposal Sent</option>
            <option value="Closed Won">Closed Won</option>
            <option value="Closed Lost">Closed Lost</option>
          </select>
        </div>
        <div className="flex space-x-2">
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded" 
            onClick={generateData}
          >
            Generate Sample Data
          </button>
          <button 
            className="bg-red-500 text-white px-4 py-2 rounded" 
            onClick={clearData}
          >
            Clear Data
          </button>
          <button 
            className="bg-yellow-500 text-white px-4 py-2 rounded" 
            onClick={() => setShowImportModal(true)}
          >
            Import Leads
          </button>
          <button 
            className="bg-green-500 text-white px-4 py-2 rounded" 
            onClick={() => setShowAddLeadModal(true)}
          >
            Add New Lead
          </button>
        </div>
      </div>

      <table className="w-full border border-gray-300 mb-6">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Business Name</th>
            <th className="border border-gray-300 p-2">Contact Name</th>
            <th className="border border-gray-300 p-2">Phone</th>
            <th className="border border-gray-300 p-2">Email</th>
            <th className="border border-gray-300 p-2">Status</th>
            <th className="border border-gray-300 p-2">Notes</th>
            <th className="border border-gray-300 p-2">Assigned To</th>
          </tr>
        </thead>
        <tbody>
          {filteredLeads.map(lead => (
            <tr key={lead.id}>
              <td className="border border-gray-300 p-2">{lead.businessName}</td>
              <td className="border border-gray-300 p-2">{lead.contactName}</td>
              <td className="border border-gray-300 p-2">{lead.phone}</td>
              <td className="border border-gray-300 p-2">{lead.email}</td>
              <td className="border border-gray-300 p-2">
                <select 
                  className="border rounded w-full py-1 px-2" 
                  value={lead.status} 
                  onChange={(e) => handleStatusChange(lead.id, e.target.value)}
                >
                  <option value="New">New</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Qualified">Qualified</option>
                  <option value="Proposal Sent">Proposal Sent</option>
                  <option value="Closed Won">Closed Won</option>
                  <option value="Closed Lost">Closed Lost</option>
                </select>
              </td>
              <td className="border border-gray-300 p-2">
                <input 
                  type="text" 
                  className="border rounded w-full py-1 px-2" 
                  value={lead.notes} 
                  onChange={(e) => handleNotesChange(lead.id, e.target.value)}
                  placeholder="Add notes"
                />
              </td>
              <td className="border border-gray-300 p-2">
                <select 
                  className="border rounded w-full py-1 px-2" 
                  value={lead.assignedTo} 
                  onChange={(e) => handleAssignedToChange(lead.id, e.target.value)}
                >
                  <option value="">Unassigned</option>
                  {salespersons.map((person, index) => (
                    <option key={index} value={person}>{person}</option>
                  ))}
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      {/* Add New Lead Modal */}
      {showAddLeadModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Add New Lead</h2>
            <form onSubmit={handleAddLeadSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Business Name *</label>
                <input 
                  type="text" 
                  name="businessName" 
                  className="border rounded w-full py-2 px-3" 
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Contact Name *</label>
                <input 
                  type="text" 
                  name="contactName" 
                  className="border rounded w-full py-2 px-3" 
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Phone *</label>
                <input 
                  type="text" 
                  name="phone" 
                  className="border rounded w-full py-2 px-3" 
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Email *</label>
                <input 
                  type="email" 
                  name="email" 
                  className="border rounded w-full py-2 px-3" 
                  required
                />
              </div>
              <div className="flex justify-end">
                <button 
                  type="button" 
                  className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                  onClick={() => setShowAddLeadModal(false)}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Add Lead
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Import Leads Modal */}
      {showImportModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-3/4 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Import Leads</h2>
            <p className="mb-4 text-gray-700">Select a file to import leads (functionality not implemented in this mockup).</p>
            <input 
              type="file" 
              className="border rounded py-2 px-3 w-full mb-4"
              disabled
              title="File import functionality is not implemented in this mockup"
            />
            <div className="flex justify-end">
              <button 
                type="button" 
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setShowImportModal(false)}
              >
                Cancel
              </button>
              <button 
                type="button" 
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Import
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CRMComponent;
