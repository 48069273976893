import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { useSelector } from "react-redux";
import Spinner from "./Spinner";
import MobileHeader from './MobileHeader';
import { useTheme } from './ThemeContext';
import ToggleSwitch from './ToggleSwitch'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

const AddUserForm = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const [localUserData, setLocalUserData] = useState({
    FirstName: "",
    LastName: "",
    CompanyName: "",
    Phone: "",
    Email: "",
    PolicyNumber: currentUser.policyNumber,
    cardGroupID: currentUser.cardGroupID,
    Roles: "",
    isAdmin: false,
    isContractAdmin: false,
    isPolicyAdmin: false,
    isActive: true,
  });
  const [showDiscounts, setShowDiscounts] = useState(false);
  const [percentage, setPercentage] = useState(100);
  const policies = useSelector((state) => state.policies.activePolicies);
  const cardGroups = useSelector((state) => state.policies.cardGroups);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isContractAdmin, setIsContractAdmin] = useState(false);
  const [contractNumber, setContractNumber] = useState("");
  const [isPolicyAdmin, setIsPolicyAdmin] = useState(false);
  const [contracts, setContracts] = useState([]);
  const subDomain = window.location.hostname.split('.')[0];
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;

    if (name === "Phone") {
      finalValue = formatPhoneNumber(value);
    }

    if (name === "isActive") {
      finalValue = value === "true" ? true : false;
    }

    setLocalUserData({
      ...localUserData,
      [name]: finalValue,
    });
  };

  const formatPhoneNumber = (value) => { 
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const fetchContracts = async () => {
    try {
      const response = await fetch(`${apiUrl}/dataServer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            action: 'fetchContracts',
            payload: { userId: currentUser.userId }
        })
    });
      if (response.ok) {
        const data = await response.json();      
        setContracts(data);
      } else {
        throw new Error('Failed to fetch contracts');
      }
    } catch (error) {
      console.error('Error fetching contracts:', error);
    }
  };

  const handleIsContractAdminChange = (e) => {
    setIsContractAdmin(e.target.checked);
    if (!e.target.checked) {
      setContractNumber(""); // Reset contract number if not a contract admin
    }
  };

  const handleAccountChange = (e) => {
    const newSelectedValue = e.target.value;

    const selectedCardGroup = cardGroups.find(group => group.AccountNumber.toString() === newSelectedValue);
    const selectedPolicy = selectedCardGroup 
      ? policies.find(policy => policy.PolicyNumber === selectedCardGroup.PolicyNumber) 
      : policies.find(policy => policy.PolicyNumber.toString() === newSelectedValue);

    setLocalUserData({
      ...localUserData,
      PolicyNumber: selectedPolicy ? selectedPolicy.PolicyNumber : "",
      cardGroupID: selectedCardGroup ? selectedCardGroup.cardGroupID : "",
    });
  }; 

  useEffect(() => {
    if (currentUser.isAdmin || currentUser.isContractAdmin) {
      fetchContracts();
    }
  }, [currentUser.userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    const payload = {
      userId: currentUser.userId,
      Email: localUserData.Email,
      PolicyNumber: localUserData.PolicyNumber,
      cardGroupID: localUserData.cardGroupID || '',
      isActive: localUserData.isActive,
      isAdmin: localUserData.isAdmin || false,
      FirstName: localUserData.FirstName,
      LastName: localUserData.LastName,
      Phone: localUserData.Phone,
      isContractAdmin: isContractAdmin || false,
      ContractId: contractNumber,
      isPolicyAdmin: isPolicyAdmin || false,
      Roles: localUserData.Roles || '',
      subDomain: subDomain,
      ShowDiscounts: showDiscounts,
      Percentage: showDiscounts ? percentage : null,
    };   
    try {      
      const response = await fetch(`${apiUrl}/dataServer`, { 
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          action: 'sendCustomEmailAndInvite',
          payload: payload
        })
      });

      const data = await response.json();

      if (response.ok) {       
        setAlertMessage('Success! Email sent to user with invite token.');        
      } else {
        console.log('Failed to add user:', data);
        setAlertMessage(`Failed to add user: ${data}`);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setAlertMessage(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mobile-container mx-auto px-4 sm:px-6 lg:px-8">
      <MobileHeader theme={theme} />
      <div className="text-end mb-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-gray-700 text-sm mr-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
          Go Back
        </button>
      </div>
      <h1 className="text-2xl font-semibold m-8">Add User</h1>
      {isLoading && <Spinner />}
      {alertMessage && (
        <div
          className={`alert p-4 rounded ${alertMessage.startsWith("Success!")
              ? "bg-green-200 text-green-600"
              : "bg-red-200 text-red-600"
            } flex justify-between items-center`}
        >
          <span>{alertMessage}</span>
          <button onClick={() => setAlertMessage("")}>Close</button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-2 m-8">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            First Name
          </label>
          <input
            type="text"
            id="FirstName"
            name="FirstName"
            value={localUserData.FirstName}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Last Name
          </label>
          <input
            type="text"
            id="LastName"
            name="LastName"
            value={localUserData.LastName}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone
          </label>
          <input
            type="tel"
            id="Phone"
            name="Phone"
            value={localUserData.Phone}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="Email"
            name="Email"
            value={localUserData.Email}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>     

        <div>
          <label htmlFor="Roles" className="block text-sm font-medium text-gray-700">
            Role
          </label>
          <select
            id="Roles"
            name="Roles"
            value={localUserData.Roles}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          >
            <option value="" disabled>
              Select a role
            </option>
            <option value="User">User</option>
            <option value="Driver">Driver</option>
          </select>
        </div>

        {/* Show Discounts Toggle and Percentage Field for Drivers */}
        {localUserData.Roles === "Driver" && (
          <>
            <div className="flex items-center mt-4">
              <label className="block text-sm font-medium text-gray-700 mr-4">
                Show Discounts
              </label>
              <ToggleSwitch
                isChecked={showDiscounts}
                onToggle={() => setShowDiscounts(!showDiscounts)}
                primaryColor={theme.primary}
              />
            </div>
            {showDiscounts && (
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Percentage
                </label>
                <input
                  type="number"
                  id="Percentage"
                  name="Percentage"
                  value={percentage}
                  onChange={(e) => setPercentage(Number(e.target.value))}
                  className="mt-1 p-2 w-full border rounded-md"
                  min="1"
                  max="100"
                  required
                />
              </div>
            )}
          </>
        )}

        {(currentUser.isContractAdmin || currentUser.isAdmin) && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Account
          </label>
          <select
            id="PolicyNumber"
            name="PolicyNumber"
            value={localUserData.cardGroupID ? cardGroups.find(group => group.cardGroupID === localUserData.cardGroupID)?.AccountNumber : localUserData.PolicyNumber}
            onChange={handleAccountChange}
            className="mt-1 p-2 w-full border rounded-md"
            required={currentUser.isAdmin || currentUser.isContractAdmin || currentUser.isPolicyAdmin}
          >
            <option value="" disabled>
              Select a policy
            </option>
            {policies &&
              policies.map((policy, index) => (
                <option key={policy.ID} value={policy.PolicyNumber}>
                  {policy.PolicyNumber} - {policy.Description} (Policy Level)
                </option>
              ))}
            {cardGroups &&
              cardGroups.map((group, index) => (
                <option key={group.cardGroupID} value={group.AccountNumber}>
                 {group.AccountNumber} - {group.groupName}
                </option>
              ))}
          </select>
        </div>
        )}

        {(currentUser.isAdmin || currentUser.isContractAdmin || currentUser.isPolicyAdmin) && (
          <fieldset className="border p-4 rounded my-4">
            <legend className="font-bold">User Settings</legend>
            {currentUser.isAdmin && (
                <div className="py-3">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isAdmin"
                    name="isAdmin"
                    checked={localUserData.isAdmin}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "isAdmin", value: e.target.checked },
                      })
                    }
                    className="form-checkbox h-5 w-5 text-blue-600 mr-4"
                  />
                  <label
                    htmlFor="isAdmin"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Network Administrator
                  </label>
                </div>
                <p className="pl-9 text-gray-600">
                  WARNING: THIS WILL GIVE THE USER ACCESS TO ALL POLICIES & ADMIN SETTINGS
                </p>
              </div>

            )}
            
            {currentUser.isAdmin && (
            <div className="py-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="isContractAdmin"
                  name="isContractAdmin"
                  checked={isContractAdmin}
                  onChange={handleIsContractAdminChange}
                  className="form-checkbox h-5 w-5 text-blue-600 mr-4"
                />
                <label
                  htmlFor="isContractAdmin"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contract Administrator
                </label>
              </div>
              <p className="pl-9 text-gray-600">
                Enables access to all policies related to a contract.
              </p>
            </div>
            )}
            {(isContractAdmin && currentUser.isAdmin) && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600" htmlFor="contract">
                  Contract
                </label>
                <select
                  className="w-full px-3 py-2 mt-1 text-gray-900 border rounded-md focus:outline-none focus:border-blue-500"
                  id="contract"
                  name="contract"
                  value={contractNumber}
                  required
                  onChange={(e) => setContractNumber(e.target.value)}
                >
                  <option value="" disabled>
                    Select a contract
                  </option>
                  {contracts.map((contract) => (
                    <option key={contract.id} value={contract.contractId}>
                      {contract.contractId} - {contract.description}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {(currentUser.isPolicyAdmin || currentUser.isContractAdmin || currentUser.isAdmin) && (
            <div className="py-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="isPolicyAdmin"
                  name="isPolicyAdmin"
                  checked={isPolicyAdmin}
                  onChange={(e) => setIsPolicyAdmin(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600 mr-4"
                />
                <label htmlFor="isPolicyAdmin" className="block text-sm font-medium text-gray-700">
                  Policy Administrator
                </label>
              </div>
              <p className="pl-9 text-gray-600">
                Enables access to all cards and accounts for the selected policy.
              </p>
            </div>
            )}
          </fieldset>        
        )}

        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="px-4 py-2 text-white rounded themed-button"
          >
            Add & Invite User
          </button>
        </div>        
      </form>
    </div>
  );
};

export default AddUserForm;
