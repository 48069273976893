import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';
import MainTableV2 from './MainTableV2';
import { useSelector } from 'react-redux';
import PageHeader from './PageHeader';
import TransactionModalAdmin from './TransactionModalAdmin';
import moment from 'moment-timezone';
import MobileHeader from './MobileHeader';

const TransactionTableAdmin = () => {
  const { theme } = useTheme();
  const { currentUser } = useAuth();
  const primaryColor = theme.primary;
  const tenantId = theme.tenantId;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransactionDetails, setSelectedTransactionDetails] = useState(null);
  const policies = useSelector((state) => state.policies.activePolicies);
  const [policyFilter, setPolicyFilter] = useState('');
  const filterConfig = [
    { key: 'TransactionId', type: 'number' },
    { key: 'policyNumber', type: 'number' },
    { key: 'locationName', type: 'string' },
    { key: 'locationCity', type: 'string' },
    { key: 'locationstate', type: 'string' },
    { key: 'AmountSum', type: 'number' },
    { key: 'UserAmountSum', type: 'number' },
    { key: 'Profit', type: 'number' },
    { key: 'TransactionDate', type: 'date' }
  ];



  const handleViewDetailsClick = async (TransactionId) => {
    setLoading(true);
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminTransactionDetails?', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: currentUser?.userId, TransactionId })
      });

      const data = await response.json();
      setSelectedTransactionDetails(data);
      setIsModalOpen(true);
      setLoading(false);

    } catch (error) {
      setAlertMessage(`Error fetching transaction details: ${error.message}`);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTransactionDetails(null);
  };

  const clearAlert = () => setAlertMessage(null);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://fuellink-db-ui.azurewebsites.net/api/getAdminTransactions?', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: currentUser?.userId,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        })
      });

      if (response.ok) {
        const fetchedData = await response.json();
        setData(fetchedData);
      } else {
        setAlertMessage(`Server Error: ${await response.text()}`);
      }
    } catch (error) {
      setAlertMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [tenantId]);

  const handleFilterSubmit = () => {
    fetchTransactions();
  };

  const filteredData = useMemo(() => {
    let filtered = data;
    let sum = 0;

    // Text search filter
    if (searchQuery !== '') {
      filtered = data.filter((row) => {
        return Object.values(row).some((s) =>
          s && s.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    // Date filter
    if (startDate || endDate) {
      filtered = filtered.filter((row) => {
        const rowDate = new Date(row.TransactionDate);
        return (!startDate || rowDate >= new Date(startDate)) && (!endDate || rowDate <= new Date(endDate));
      });
    }

    if (policyFilter) {
      filtered = filtered.filter((transaction) => {
        const policyNumberInt = parseInt(transaction.policyNumber, 10);
        const policyFilterInt = parseInt(policyFilter, 10);
        return policyNumberInt === policyFilterInt;
      });
    }

    // Calculate the total amount and number of transactions
    filtered.forEach((transaction) => {
      sum += parseFloat(transaction.UserAmountSum) || 0;
    });

    let profit = 0;
    filtered.forEach((transaction) => {
      profit += parseFloat(transaction.Profit) || 0;
    });

    setTotalProfit(profit);
    setTotalAmount(sum);
    setTotalTransactions(filtered.length);

    return filtered;
  }, [searchQuery, data, startDate, endDate, policyFilter]);

  const columns = useMemo(() => [
    {
      name: "Date",
      selector: row => {
        const date = moment(row.TransactionDate).tz('America/Chicago');
        const formattedDate = date.format('MM/DD/YYYY');
        const formattedTime = date.format('hh:mm:ss A');
        return `${formattedDate} ${formattedTime}`;
      },
      reorder: true,
      sortable: true,
    },
    {
      name: 'Policy',
      selector: row => row.policyNumber,
      reorder: true,
      sortable: true,
    },
    {
      name: 'Transaction ID',
      selector: row => row.TransactionId,
      reorder: true,
      sortable: true,
    },
    {
      name: 'Location',
      selector: row => row.locationName,
      reorder: true,
      sortable: true,
    },
    {
      name: 'City',
      selector: row => row.locationCity,
      reorder: true,
      sortable: true,
    },
    {
      name: 'State',
      selector: row => row.locationstate,
      reorder: true,
      sortable: true,
    },
    {
      name: 'Cost',
      selector: row => `$${parseFloat(row.AmountSum || 0).toFixed(2)}`,
      reorder: true,
      sortable: true,
    },
    {
      name: 'User Amount',
      selector: row => `$${parseFloat(row.UserAmountSum || 0).toFixed(2)}`,
      reorder: true,
      sortable: true,
    },
    {
      name: 'Profit',
      selector: row => row.Profit,
      cell: row => (
        <span style={{ color: row.Profit < 0 ? 'red' : 'black' }}>
          {`$${parseFloat(row.Profit || 0).toFixed(2)}`}
        </span>
      ),
      reorder: true,
      sortable: true,
    },
    {
      name: 'Details',
      cell: row => (
        <button
          className="themed-button"
          onClick={() => handleViewDetailsClick(row.TransactionId)}
        >
          View
        </button>
      ),
      reorder: true,
    },
  ], [handleViewDetailsClick]);


  return (
    <>
      <MobileHeader theme={theme} />
      <div className='mobile-container'>
        <PageHeader
          heading="Admin Transactions"
          context="Explore and review transaction details from the past 30 days. If you need information on transactions older than that, please utilize the reporting tool to generate the necessary reports."
        />
        <TransactionModalAdmin
          isOpen={isModalOpen}
          details={selectedTransactionDetails}
          onClose={handleCloseModal}
        />

        {/* Cards for Totals */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">

          {/* Total Transactions Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-700">Total Transactions:</h3>
            <p className="text-blue-500">{totalTransactions}</p>
          </div>

          {/* Total User Amount Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-700">Total User Amount:</h3>
            <p className="text-green-500">${totalAmount.toFixed(2)}</p>
          </div>

          {/* Total Cost Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-700">Total Cost:</h3>
            <p className="text-blue-500">${(totalAmount - totalProfit).toFixed(2)}</p>
          </div>

          {/* Total Profit Card */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-700">Total Profit:</h3>
            <p className="text-purple-500">${totalProfit.toFixed(2)}</p>
          </div>
        </div>

        <div className="bg-gray-300 p-4 rounded-lg mb-6 my-4">
          <div className="grid md:grid-cols-3 gap-4 items-end">
            {/* Date Range Filters */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-medium text-gray-700">Start Date:</label>
              <input
                type="date"
                value={startDate.toISOString().split('T')[0]}
                onChange={(e) => setStartDate(e.target.value ? new Date(e.target.value) : null)}
                className="bg-white border rounded p-2"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-medium text-gray-700">End Date:</label>
              <input
                type="date"
                value={endDate.toISOString().split('T')[0]}
                onChange={(e) => setEndDate(e.target.value ? new Date(e.target.value) : null)}
                className="bg-white border rounded p-2"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-medium text-gray-700">Select Policy:</label>
              <div className="flex items-center space-x-2">
                <select
                  value={policyFilter}
                  onChange={(e) => setPolicyFilter(e.target.value)}
                  className="bg-white rounded-md p-2 flex-grow"
                >
                  <option value="">All Policies</option>
                  {policies.map((policy) => (
                    <option key={policy.PolicyNumber} value={policy.PolicyNumber}>
                      {policy.PolicyNumber} - {policy.Description}
                    </option>
                  ))}
                </select>
                <button
                  onClick={handleFilterSubmit}
                  className="themed-button px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <MainTableV2
          columns={columns}
          data={filteredData}
          primaryColor={primaryColor}
          loading={loading}
          alertMessage={alertMessage}
          clearAlert={clearAlert}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filterConfig={filterConfig}
        >
        </MainTableV2>

      </div>
    </>
  );
};

export default TransactionTableAdmin;

